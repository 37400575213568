import React from 'react';
import Svg from 'react-inlinesvg';

import withToc from '../withToc';
import { BottomNavigationBar, BottomNavigationLink, PageTitle } from './style';

const BottomNavigation = ({ pathname, toc }) => {
  const toggleSidebar = () => {
    const event = new CustomEvent('sidebartogglerequest');
    window.dispatchEvent(event);
  };

  const isHomepage = pathname === '/';
  const linksLength = toc.length + (isHomepage ? 0 : 1);
  const linkStyle = { width: `${100 / linksLength}%` };

  return (
    <BottomNavigationBar>
      {!isHomepage && (
        <BottomNavigationLink
          to={pathname}
          onClick={toggleSidebar}
          style={linkStyle}
        >
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
            <path
              d="M496 384H176a16 16 0 00-16 16v32a16 16 0 0016 16h320a16 16 0 0016-16v-32a16 16 0 00-16-16zm0-320H176a16 16 0 00-16 16v32a16 16 0 0016 16h320a16 16 0 0016-16V80a16 16 0 00-16-16zm0 160H176a16 16 0 00-16 16v32a16 16 0 0016 16h320a16 16 0 0016-16v-32a16 16 0 00-16-16z"
              opacity="0.4"
            ></path>
            <path d="M80 368H16a16 16 0 00-16 16v64a16 16 0 0016 16h64a16 16 0 0016-16v-64a16 16 0 00-16-16zm0-320H16A16 16 0 000 64v64a16 16 0 0016 16h64a16 16 0 0016-16V64a16 16 0 00-16-16zm0 160H16a16 16 0 00-16 16v64a16 16 0 0016 16h64a16 16 0 0016-16v-64a16 16 0 00-16-16z"></path>
          </svg>
          <PageTitle>Contents</PageTitle>
        </BottomNavigationLink>
      )}
      {toc.map(page => {
        let pathMatches = true;
        if (page.path) {
          const articlePath = page.path.split('/').slice(1);
          const currentArticlePath = pathname.split('/').slice(1);
          for (let i = 0; i < 1; i++) {
            pathMatches =
              pathMatches && currentArticlePath[i] === articlePath[i];
          }
        }

        return (
          <BottomNavigationLink
            to={page.path}
            active={pathMatches ? 1 : 0}
            style={linkStyle}
            key={page.path}
          >
            {page.icon && <Svg src={`/page-icons/${page.icon}`} />}
            <PageTitle>{page.shortTitle || page.title}</PageTitle>
          </BottomNavigationLink>
        );
      })}
    </BottomNavigationBar>
  );
};

export default withToc(BottomNavigation);
