import React from 'react';

import Container from '../Container';
import { SupportSection } from './style';

const GetSupport = () => (
  <SupportSection>
    <Container>
      <h2>Get support</h2>
      <p>Contact poq support for any other questions...</p>
      <a href="https://support.poqcommerce.com/hc/en-us/requests/new">
        Contact a support agent
      </a>
    </Container>
  </SupportSection>
);

export default GetSupport;
