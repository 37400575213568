import styled from 'styled-components';

import Link from '../../components/Link';
import { navbarHeight, sidebarWidth } from '../../components/Layout/constants';

export const SidebarArea = styled.aside`
  flex: 0 0 auto;
  position: fixed;
  overflow: auto;
  top: 0;
  left: 0;
  bottom: 0;
  transform: ${props => (props.isOpen ? 'translateX(0)' : 'translateX(-100%)')};
  z-index: 100;
  width: ${sidebarWidth}px;
  min-width: ${sidebarWidth}px;
  min-height: calc(100vh - ${navbarHeight}px);
  padding: 16px 24px;
  border-bottom: 1px solid #d4dadf;
  box-shadow: 0 5px 8px 0 rgba(116, 129, 141, 0.2);
  background: ${props => (props.theme.isDark ? props.theme.bg : '#f5f7f9')};
  color: ${props => props.theme.fg};
  transition: transform 0.25s ease-in-out, background 0.2s ease-in-out,
    border-color 0.2s ease-in-out;

  @media (min-width: 992px) {
    position: relative;
    top: 0;
    bottom: initial;
    width: calc((100% - 1448px) / 2 + ${sidebarWidth}px);
    padding-left: calc((100% - 1448px) / 2);
    padding-right: 0;
    padding-bottom: 0;
    border-right: 1px solid
      ${props => (props.theme.isDark ? '#333' : '#e6ecf1')};
    border-bottom: none;
    box-shadow: none;
  }

  // Style the top list so that it can be scrollable when max height is reached
  > ul {
    overflow: auto;
    margin-bottom: 50px;
  }
`;

export const List = styled.ul`
  margin: 0;
  margin-left: ${props =>
    props.level === 2
      ? '15px'
      : props.level > 2
      ? `${props.level * 2}px`
      : props.level > 1
      ? '4px'
      : '0'};
  padding: 0;
  border-left: ${props =>
    props.level === 2
      ? `1px solid ${props.theme.isDark ? '#242327' : '#e6ecf1'}`
      : 'none'};
  list-style: none;
  transition: border-color 0.2s ease-in-out;
`;

export const ListItem = styled.li`
  display: ${props => (props.level > 1 && !props.expanded ? 'none' : 'block')};
  margin: 0;
  font-weight: 400;
`;

export const ListLink = styled(Link)`
  display: flex;
  align-items: center;
  margin: 0;
  padding: 10px 24px 10px 16px;
  outline: none;
  background: ${props =>
    props.active ? (props.theme.isDark ? '#1d1b23' : '#fff') : 'none'};
  font-size: 14px;
  color: ${props => (props.active ? 'var(--primary)' : 'inherit')};
  transition: background 0.2s ease-in-out, color 0.2s ease-in-out,
    border-color 0.2s ease-in-out;

  @media (min-width: 768px) {
    border: 1px solid
      ${props =>
        props.active
          ? props.theme.isDark
            ? '#242327'
            : '#e6ecf1'
          : 'transparent'};
    ${props => props.level === 2 && `border-left-color: transparent;`};
    border-right: none;
  }

  ${props =>
    !props.active &&
    `
    &:hover,
    &:focus {
      background: ${props.theme.isDark ? '#242327' : '#e6ecf1'};
    }
  `}

  > svg {
    width: 28px;
    height: 28px;
    margin-right: 8px;
  }
`;

export const LinkChevron = styled.span`
  margin-left: auto;
  color: rgba(101, 89, 113, 0.4);
`;

export const StickyNav = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  position: fixed;
  z-index: 101;
  left: 0;
  bottom: 0;
  transform: ${props => (props.isOpen ? 'translateX(0)' : 'translateX(-100%)')};
  height: 50px;
  width: ${sidebarWidth}px;
  min-width: ${sidebarWidth}px;
  box-shadow: -3px -3px 6px -2px
    ${props => (props.theme.isDark ? props.theme.bg : '#f5f7f9')};
  background: ${props => (props.theme.isDark ? props.theme.bg : '#f5f7f9')};
  font-size: 0.8rem;
  transition: transform 0.25s ease-in-out, background 0.2s ease-in-out,
    border-color 0.2s ease-in-out;

  @media (min-width: 992px) {
    width: calc((100% - 1448px) / 2 + ${sidebarWidth}px);
    padding-left: calc((100% - 1448px) / 2);
    border-right: 1px solid
      ${props => (props.theme.isDark ? '#333' : '#e6ecf1')};
  }

  svg {
    width: 20px;
    height: 20px;
  }
`;

export const StickyNavItem = styled.div`
  flex: 1;

  a,
  button {
    outline: none;
    transition: background 0.1s ease-in-out;

    &:hover,
    &:focus {
      background: ${props => (props.theme.isDark ? '#242327' : '#e6ecf1')};
    }
  }

  svg {
    margin-right: 8px;
  }
`;

export const SidebarOverlay = styled.div`
  opacity: ${props => (props.isOpen ? 1 : 0)};
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 99;
  background: rgba(255, 255, 255, 0.9);
  transition: opacity 0.25s ease-in-out;

  button {
    position: absolute;
    left: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 48px;
    height: 48px;
    margin: 0 0 16px calc(298px + (100vw - 298px - 48px) / 2);
    padding: 0;
    background: #bbb;
    border-radius: 50%;
    font-size: 2rem;
    line-height: 0;
  }
`;
