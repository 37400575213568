import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';

export const PageNavigation = styled.nav`
  display: flex;
  flex-wrap: wrap;
  padding: 16px 0;
`;

const Item = styled(Link)`
  display: flex;
  align-items: flex-start;
  width: 100%;
  margin: 0 20px 20px 0;
  padding: 20px 28px;
  border-radius: 4px;
  box-shadow: 0 0 3px
    ${props =>
      props.theme.isDark ? 'rgba(255, 255, 255, 0.1)' : 'rgba(0, 0, 0, 0.1)'};
  background: ${props => (props.theme.isDark ? '#1b1a1f' : '#fff')};
  color: inherit;
  transition: all 0.2s ease-in-out;

  @media (min-width: 768px) {
    width: calc(50% - 20px);
  }

  &:hover {
    transform: translateY(-2px);
    box-shadow: 0 2px 3px
      ${props =>
        props.theme.isDark
          ? 'rgba(255, 255, 255, 0.15)'
          : 'rgba(0, 0, 0, 0.15)'};
  }

  h5 {
    margin: 0;
    padding-top: 0;
    font-size: 1.1rem;
  }

  p {
    margin: 4px 0 0;
    font-size: 0.9rem;
  }
`;

const Icon = styled.div`
  padding: 16px;
  border-radius: 50%;
  background: ${props =>
    !props.invert && props.theme.isDark ? '#090909' : '#f6f6f6'};
  margin-right: 18px;
  filter: ${props =>
    props.invert && props.theme.isDark ? 'invert(1)' : 'none'};
  transition: background 0.2s ease-in-out, filter 0.2s ease-in-out;

  img {
    display: block;
    position: relative;
    width: 32px;
    height: 32px;
  }
`;

export const PageNavigationItem = ({
  to,
  title,
  description,
  icon,
  iconInvert = true,
  iconOffset
}) => (
  <Item to={to}>
    <Icon invert={iconInvert}>
      <img src={icon} alt="" role="presentation" style={{ top: iconOffset }} />
    </Icon>
    <div>
      <h5>{title}</h5>
      <p>{description}</p>
    </div>
  </Item>
);
