import React from 'react';
import Highlight, { defaultProps } from 'prism-react-renderer';
import Prism from 'prismjs';
import oceanicNextTheme from 'prism-react-renderer/themes/oceanicNext';

// Web snippets
import 'prismjs/components/prism-jsx';
import 'prismjs/components/prism-typescript';
import 'prismjs/components/prism-json';
import 'prismjs/components/prism-markup-templating';
import 'prismjs/components/prism-php';
import 'prismjs/components/prism-csharp';
import 'prismjs/components/prism-css';

// Android snippets
import 'prismjs/components/prism-java';
import 'prismjs/components/prism-kotlin';
import 'prismjs/components/prism-groovy';

// iOS snippets
import 'prismjs/components/prism-swift';
import 'prismjs/components/prism-ruby';
import 'prismjs/components/prism-yaml';
import 'prismjs/components/prism-bash';

const CodeBlock = props => (
  <Highlight {...defaultProps} Prism={Prism} {...props} theme={oceanicNextTheme}>
    {({ className, tokens, getLineProps, getTokenProps }) => (
      <pre className={className}>
        <code>
          {tokens.map((line, i) => (
            <div {...getLineProps({ line, key: i })}>
              {line.map((token, key) => (
                <span {...getTokenProps({ token, key })} />
              ))}
            </div>
          ))}
        </code>
      </pre>
    )}
  </Highlight>
);

export const CodeBlockMarkdownShim = props => {
  const className = props.children.props.className || '';
  const langMatches = className.match(/language-(?<lang>.*)/);
  const language =
    langMatches && langMatches.groups ? langMatches.groups.lang : '';

  return (
    <CodeBlock
      code={props.children.props.children.trim()}
      language={language}
    />
  );
};

export default CodeBlock;
