import styled from 'styled-components';

import { tabPanelStyles } from '../Tabs/style';

export const CollapseButton = styled.button`
  border: 1px solid ${props => (props.theme.isDark ? '#2c2b2f' : '#e6ecf1')};
  ${props => props.isOpen && 'border-bottom-color: transparent;'};
  border-radius: ${props => (props.isOpen ? '4px 4px 0 0' : '4px')};
  background: none;
  padding: 6px 12px;
  color: inherit;
  cursor: pointer;
  transition: border-color 0.2s ease-in-out, border-radius 0.2s ease-in-out 0.2s;
`;

export const CollapseCaret = styled.span`
  display: inline-block;
  width: 12px;
  margin-right: 8px;
  font-size: 0.75rem;
`;

export const CollapseArea = styled.div`
  position: relative;
  top: -1px;
  ${tabPanelStyles};
`;
