import styled from 'styled-components';

export const HttpArea = styled.div`
  > p,
  pre {
    margin-top: 0;
    border-radius: 0 0 4px 4px;
  }

  pre:not(:last-child) {
    margin: 0;
    border-radius: 0;
  }
`;

export const HttpInfoArea = styled.div`
  display: flex;
  align-items: center;
  margin: 0;
  border: none;
  background: #3c4257;
  border-radius: 4px 4px 0 0;
  padding: 8px 16px;
  font-size: 0.9rem;
  line-height: 1.625;
  color: #a8b1bd;

  code {
    margin: 0;
    padding: 0;
    background: none;
    font-size: inherit;
  }
`;

const methodColors = {
  GET: '#3a97d4',
  POST: '#85d996',
  DELETE: '#e56f4a'
};

export const Method = styled.code`
  margin-right: 8px !important;
  color: ${props => methodColors[props.method] || 'inherit'};
`;
