import React from 'react';

import {
  GuidesSection,
  GuidesContainer,
  GuideLink,
  GuideHref,
  Icon,
  GuideContent
} from './style';

const Guides = () => (
  <GuidesSection>
    <GuidesContainer>
      <GuideLink to="/integrations">
        <Icon src="/homepage-guides/getting-started.png" alt="" />
        <GuideContent>
          <h3>Getting started</h3>
          <p>Set up the essential integrations for your poq app</p>
        </GuideContent>
      </GuideLink>
      <GuideLink to="/guides">
        <Icon src="/homepage-guides/user-guides.png" alt="" />
        <GuideContent>
          <h3>User guides</h3>
          <p>Manage content and design in your poq app</p>
        </GuideContent>
      </GuideLink>
      <GuideLink to="/developers">
        <Icon src="/homepage-guides/developers.png" alt="" />
        <GuideContent>
          <h3>Developers</h3>
          <p>Documentation for poq developer tools</p>
        </GuideContent>
      </GuideLink>
      <GuideLink to="/platform">
        <Icon src="/homepage-guides/platform.png" alt="" />
        <GuideContent>
          <h3>Poq Platform</h3>
          <p>Learn about the poq platform and our latest releases</p>
        </GuideContent>
      </GuideLink>
      <GuideHref href="https://docs.poqcommerce.com/guides/support">
        <Icon src="/homepage-guides/support.png" alt="" />
        <GuideContent>
          <h3>Help & Support</h3>
          <p>Contact a support agent</p>
        </GuideContent>
      </GuideHref>
    </GuidesContainer>
  </GuidesSection>
);

export default Guides;
