import styled from 'styled-components';

import { navbarHeight } from '../../components/Layout/constants';

export const FixedContainer = styled.div`
  position: relative;
`;

export const Headings = styled.aside`
  position: fixed;
  padding-left: 64px;
  max-height: calc(100vh - ${navbarHeight + 56}px);
  overflow: auto;
  transition: opacity 0.25s ease-in-out;
  color: ${props =>
    props.theme.isDark ? 'rgba(255, 255, 255, 0.7)' : 'rgba(0, 0, 0, 0.7)'};

  h5 {
    margin-top: 0;
    font-size: 0.7rem;
    text-transform: uppercase;
  }
`;

export const HeadingList = styled.ul`
  width: 224px;
  margin: 1em 0 0;
  padding: 0;
  list-style: none;
  font-size: 0.8rem;
  line-height: 1.5;

  a {
    color: inherit;
  }
`;

export const HeadingItem = styled.li`
  position: relative;
  padding: 8px 0;
  border-bottom: 1px solid
    ${props =>
      props.theme.isDark ? 'rgba(255, 255, 255, 0.07)' : 'rgba(0, 0, 0, 0.07)'};

  &:last-child {
    border-bottom: 0;
  }

  &.active {
    color: var(--primary);

    &:before {
      content: '›';
    }
  }

  &:before {
    position: absolute;
    top: 6px;
    left: -16px;
    transform: scaleY(0.8);
    font-family: 'Verdana', sans-serif;
    font-size: 20px;
    line-height: 1;
    opacity: 0.5;
  }
`;

export const HeadingLink = styled.a`
  display: block;
  transition: padding 0.25s ease-in-out;

  &:hover,
  &:focus {
    padding-left: 8px;
  }
`;
