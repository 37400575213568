import React, { useEffect, useState } from 'react';

import Container from '../Container';
import fetchPosts from './fetchPosts';
import {
  WhatsNewSection,
  WhatsNewTitle,
  Items,
  Post,
  Thumbnail
} from './style';

const WhatsNew = () => {
  const count = 3;

  const [posts, setPosts] = useState([]);
  useEffect(() => {
    async function requestPosts() {
      setPosts(await fetchPosts(count));
    }

    if (posts.length === 0) {
      requestPosts();
    }
  });

  return (
    <WhatsNewSection>
      <Container>
        <WhatsNewTitle>Discover what's new</WhatsNewTitle>
        <Items>
          {Array.from(Array(count), (_, i) => {
            const post = posts[i];
            return (
              <Post href={post ? post.link : '#'} key={i}>
                <Thumbnail
                  src={post ? post.thumbnail : null}
                  alt={post ? post.title : ''}
                  loaded={!!post}
                />
                <h3>{post ? post.title : null}</h3>
                <p>{post ? post.excerpt : null}</p>
              </Post>
            );
          })}
        </Items>
      </Container>
    </WhatsNewSection>
  );
};

export default WhatsNew;
