// TODO: Promise.all these requests
const fetchTimeline = (boardId, wantedLists = []) => {
  const response = {};

  return fetch(
    `https://api.trello.com/1/boards/${boardId}?fields=customFields&customFields=true`
  )
    .then(r => r.json())
    .then(board => {
      response.customFields = board.customFields;
    })
    .then(() =>
      fetch(
        `https://api.trello.com/1/boards/${boardId}/lists?cards=all&card_fields=desc,name,labels,cover&fields=name,cards`
      )
        .then(r => r.json())
        .then(res => {
          if (!res || res.length === 0) {
            throw new Error('No lists');
          }

          if (res.error) {
            throw new Error(res.error);
          }

          response.lists = res.filter(
            list =>
              wantedLists.length === 0 || wantedLists.indexOf(list.name) === 0
          );

          return response;
        })
    );
};

export default fetchTimeline;
