import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

export const Circle = styled.div`
  display: inline-block;
  position: relative;
  width: ${props =>
    props.size === 'xs' ? 16 : props.size === 'sm' ? 32 : 50}px;
  height: ${props =>
    props.size === 'xs' ? 16 : props.size === 'sm' ? 32 : 50}px;
`;

export const Child = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;

  &:before {
    display: block;
    margin: 0 auto;
    width: 15%;
    height: 15%;
    border-radius: 50%;
    background-color: #7d42b8;
    animation: spinner 1.2s infinite ease-in-out both;
    content: '';
  }

  ${Array.from(
    Array(11),
    (_, i) => `
      &:nth-child(${i + 2}) {
        transform: rotate(${30 * (i + 1)}deg);

        &:before {
          animation-delay: -${1.1 - i * 0.1}s;
        }
      }
    `
  )} @keyframes spinner {
    0%,
    80%,
    100% {
      transform: scale(0);
    }
    40% {
      transform: scale(1);
    }
  }
`;

const Spinner = ({ size = 'md', theme = 'primary', ...props }) => (
  <Circle size={size}>
    {Array.from(Array(12), (_, i) => (
      <Child key={i} theme={theme} {...props} />
    ))}
  </Circle>
);

Spinner.propTypes = { size: PropTypes.string, theme: PropTypes.string };

export default Spinner;
