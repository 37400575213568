import React, { useCallback, useEffect, useState } from 'react';
import { useGlobalEvent } from 'beautiful-react-hooks';
import {
  Tab as ReactTab,
  TabList,
  Tabs as ReactTabs,
  TabPanel as ReactTabPanel
} from 'react-tabs';
import PropTypes from 'prop-types';
import 'react-tabs/style/react-tabs.css';

import headingSlug from '../../../utils/headingSlug';
import reactChildrenToMarkdown from '../../../utils/reactChildrenToMarkdown';
import { TabsArea } from './style';

// Just a placeholder, it's props are used as data
export const Tab = () => null;

export const Tabs = ({ children, noPanelPadding }) => {
  const [selectedIndex, setIndex] = useState(0);

  const labels = children.map(child => headingSlug(child.props.label));

  const setIndexFromHash = useCallback(
    hash => {
      const index = labels.indexOf(hash.slice(1));
      if (index > -1) {
        setIndex(index);
      }
    },
    [setIndex, labels]
  );

  // Watch for changes in the hash and open the appropriate tab
  const onHashChange = useGlobalEvent('headingshashchange');
  onHashChange(e => setIndexFromHash(e.detail.hash));

  useEffect(() => {
    if (typeof window !== 'undefined') {
      setIndexFromHash(window.location.hash);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <TabsArea noPanelPadding={noPanelPadding}>
      <ReactTabs onSelect={setIndex} selectedIndex={selectedIndex}>
        <TabList>
          {React.Children.map(children, child => (
            <ReactTab>{child.props.label}</ReactTab>
          ))}
        </TabList>
        {React.Children.map(children, child => {
          let panelContents = child.props.children;
          if (typeof panelContents === 'string') {
            panelContents = reactChildrenToMarkdown(panelContents);
          }

          return <ReactTabPanel>{panelContents}</ReactTabPanel>;
        })}
      </ReactTabs>
    </TabsArea>
  );
};

Tabs.propTypes = { children: PropTypes.node.isRequired };
