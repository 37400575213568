import styled from 'styled-components';
import { Link } from 'gatsby';

export const SearchArea = styled.div`
  flex: 1;
  display: flex;
  align-items: center;

  .DocSearch-Screen-Icon {
    display: flex;
    justify-content: center;
  }

  .DocSearch-Screen-Icon svg {
    fill: none;
  }
`;

export const SearchButton = styled.button`
  display: flex;
  align-items: center;
  width: 200px;
  margin-left: 16px;
  padding: 8px;
  border: none;
  border-radius: 4px;
  background: ${props =>
    props.isHomepage
      ? 'rgba(255, 255, 255, 0.07)'
      : props.theme.isDark
      ? 'rgba(215, 214, 222, 0.07)'
      : 'rgba(90, 94, 154, 0.06)'};
  text-align: left;
  color: inherit;
  appearance: none;
  cursor: pointer;
  transition: background 0.2s ease-in-out;

  &:hover,
  &:focus {
    background: ${props =>
      props.isHomepage
        ? 'rgba(255, 255, 255, 0.05)'
        : props.theme.isDark
        ? 'rgba(215, 214, 222, 0.05)'
        : 'rgba(90, 94, 154, 0.1)'};
  }

  span {
    display: block;
    margin-left: 10px;
    margin-right: auto;
  }

  kbd {
    margin-left: 5px;
    padding: 0 7px;
    border: 1px solid rgb(229, 232, 236);
    border-radius: 5px;
    background-color: rgb(255, 255, 255);
    font: inherit;
    font-size: 0.8125rem;
    font-weight: 700;
    line-height: 20px;
    color: ${props =>
      props.isHomepage ? '#666' : props.theme.isDark ? '#ccc' : '#666'};
  }
`;

export const NewStartScreenArea = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 16px;
  width: 100%;
  padding: 16px 16px 32px;
`;

export const NewStartScreenCategory = styled.div`
  display: flex;
  flex-direction: column;
`;

export const NewStartScreenTitle = styled.div`
  display: flex;
  align-items: center;
  padding: 16px 8px;
  font-size: 0.8125rem;
  color: rgb(70, 80, 90);

  svg {
    width: 18px;
    height: 18px;
    margin-right: 8px;
    color: var(--primary);
  }
`;

export const NewStartScreenItem = styled(Link)`
  display: flex;
  align-items: center;
  width: 100%;
  padding: 4px 8px;
  font-size: 0.875rem;
  font-weight: 600;
  color: var(--primary);

  &:hover svg,
  &:focus svg {
    margin-left: 10px;
  }

  svg {
    width: 8px;
    height: 8px;
    margin-left: 6px;
    transition: margin 0.2s ease 0s;
  }
`;
