import React from 'react';

import { HttpArea, HttpInfoArea } from '../HttpRequest/style';

const HttpResponse = ({ children, status }) => (
  <HttpArea>
    <HttpInfoArea>
      <code>{status}</code>
    </HttpInfoArea>
    {children}
  </HttpArea>
);

export default HttpResponse;
