import React from 'react';
import styled from 'styled-components';

import { StickyNavItem } from './style';

const HelpLink = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 50px;
  color: inherit;

  svg {
    color: var(--primary);
  }
`;

const GetHelp = () => (
  <StickyNavItem>
    <HelpLink href="https://support.poqcommerce.com/hc/en-us/requests/new">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512">
        <path
          d="M208 352c-41 0-79.1-9.3-111.3-25-21.8 12.7-52.1 25-88.7 25a7.83 7.83 0 01-7.3-4.8 8 8 0 011.5-8.7c.3-.3 22.4-24.3 35.8-54.5-23.9-26.1-38-57.7-38-92C0 103.6 93.1 32 208 32s208 71.6 208 160-93.1 160-208 160z"
          opacity="0.4"
        ></path>
        <path d="M576 320c0 34.3-14.1 66-38 92 13.4 30.3 35.5 54.2 35.8 54.5a8 8 0 011.5 8.7 7.88 7.88 0 01-7.3 4.8c-36.6 0-66.9-12.3-88.7-25-32.2 15.8-70.3 25-111.3 25-86.2 0-160.2-40.4-191.7-97.9A299.82 299.82 0 00208 384c132.3 0 240-86.1 240-192a148.61 148.61 0 00-1.3-20.1C522.5 195.8 576 253.1 576 320z"></path>
      </svg>
      Help
    </HelpLink>
  </StickyNavItem>
);

export default GetHelp;
