import styled from 'styled-components';

export const Stack = styled.div`
  display: none;
  position: absolute;
  left: 32px;
  transform: rotate(4deg);
  pointer-events: none;

  @media (min-width: 768px) {
    display: block;
    top: -140px;
    left: 35%;
  }

  @media (min-width: 1280px) {
    top: 140px;
    left: 40%;
  }

  @media (min-width: 1400px) {
    top: 170px;
    left: 52%;
  }
`;

export const Notification = styled.div`
  position: absolute;
  top: 0;
  width: 280px;
  height: 72px;
  padding: 8px;
  border-radius: 12px;
  border: 1px solid rgba(209, 213, 219, 0.3);
  background-color: #fff;
  // Imitate the backdrop-filter with a similar gradient
  background: linear-gradient(180deg, #b5b5db 0%, #e3e6f9 100%);
  font-family: -apple-system, Arial, sans-serif;
  color: #000;
  animation: stack 9s infinite;
  will-change: transform;

  @supports ((backdrop-filter: none)) {
    background: none;
    background-color: rgba(255, 255, 255, 0.6);
    backdrop-filter: blur(16px) saturate(180%);
  }

  &:nth-child(2) {
    animation-delay: -3s;
  }

  &:nth-child(3) {
    animation-delay: -6s;
  }

  h6 {
    margin: 0 0 2px;
    font-family: inherit;
    font-size: 12px;
  }

  p {
    margin: 0;
    font-size: 12px;
  }

  @keyframes stack {
    0%,
    30% {
      z-index: 1;
      transform: translateY(20px) scale(0.9);
    }
    34%,
    66% {
      z-index: 2;
      transform: translateY(10px) scale(0.95);
    }
    70%,
    100% {
      z-index: 3;
      transform: translateY(0) scale(1);
    }
  }
`;

export const App = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 4px;
  font-size: 9px;
  text-transform: uppercase;
`;

export const AppIcon = styled.div`
  width: 14px;
  height: 14px;
  margin-right: 6px;
  border-radius: 4px;
  background: rgba(255, 255, 255, 0.5);
`;
