import React from 'react';

import {
  NewStartScreenArea,
  NewStartScreenCategory,
  NewStartScreenItem,
  NewStartScreenTitle
} from './style';
import FileSearch from './file-search-duotone.svg';
import PhoneLaptop from './phone-laptop-duotone.svg';
import Code from './code-duotone.svg';
import Store from './store-duotone.svg';
import ChevronRight from './chevron-right-solid.svg';

const NewStartScreen = () => {
  const startScreenOptions = [
    {
      category: {
        name: 'Popular searches',
        icon: <FileSearch />
      },
      items: [
        {
          name: 'Web Checkout',
          href: '/guides/quick-start/cart-transfer-guide/web-checkout'
        },
        { name: 'Product Feed', href: '/guides/quick-start/product-feed' },
        {
          name: 'Cart Transfer Guide',
          href: '/guides/quick-start/cart-transfer-guide'
        }
      ]
    },
    {
      category: {
        name: 'App Manager and CMS',
        icon: <PhoneLaptop />
      },
      items: [
        { name: 'App Styler', href: '/guides/app-styler' },
        { name: 'Category Manager', href: '/guides/category-manager' },
        { name: 'Poq CMS', href: '/guides/cms' }
      ]
    },
    {
      category: {
        name: 'Developers',
        icon: <Code />
      },
      items: [
        { name: 'Android', href: '/developers/android' },
        { name: 'iOS', href: '/developers/ios' },
        { name: '.NET', href: '/developers/dotnet' }
      ]
    },
    {
      category: {
        name: 'Integrations',
        icon: <Store />
      },
      items: [
        { name: 'AppsFlyer', href: '/integrations/appsflyer' },
        { name: 'Emarsys', href: '/integrations/emarsys' },
        { name: 'Firebase', href: '/integrations/firebase' }
      ]
    }
  ];

  return (
    <NewStartScreenArea className="DocSearch-NewStartScreen">
      {startScreenOptions.map(({ category, items }) => (
        <NewStartScreenCategory key={category.name}>
          <NewStartScreenTitle>
            {category.icon}
            {category.name}
          </NewStartScreenTitle>
          {items.map(({ name, href }) => (
            <NewStartScreenItem
              key={name}
              href={href}
              className="DocSearch-NewStartScreenItem"
            >
              {name}
              <ChevronRight className="DocSearch-NewStartScreenItemIcon" />
            </NewStartScreenItem>
          ))}
        </NewStartScreenCategory>
      ))}
    </NewStartScreenArea>
  );
};

export default NewStartScreen;
