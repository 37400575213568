import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
  body {
    background: ${props => props.theme.bg};
    color: ${props => props.theme.fg};
    transition: background 0.2s ease-in-out, color 0.2s ease-in-out;
  }

  .goverlay {
    background: ${props => props.theme.bg};
  }

  .DocSearch-Container {
    opacity: 0;
    z-index: 1000;
    background-color: rgba(32, 38, 45, 0.2);
    backdrop-filter: blur(2px);
    transition: opacity 0.2s ease-in-out;
  }

  .DocSearch-Modal {
    box-shadow: rgba(70, 80, 90, 0.2) 0 4px 20px;
  }

  .DocSearch-SearchBar {
    border-bottom: 1px solid rgb(229, 232, 236);
    border-top-color: rgb(229, 232, 236);
    border-right-color: rgb(229, 232, 236);
    border-left-color: rgb(229, 232, 236);
    padding: 10px;
  }

  .DocSearch-Form .DocSearch-Search-Icon {
    width: 20px;
    height: 20px;
  }

  .DocSearch-Input {
    padding-left: 24px;
    appearance: none;
  }

  .DocSearch-Input:focus {
    box-shadow: none;
  }

  .DocSearch-Commands {
    display: none;
  }
`;

export default GlobalStyle;
