import React, { useState } from 'react';
import { Transition } from 'react-transition-group';
import { useGlobalEvent, useWindowResize } from 'beautiful-react-hooks';

import withToc from '../withToc';
import Button from '../Button';
import Node from './Node';
import GetHelp from './GetHelp';
import DarkModeToggle from '../Header/DarkModeToggle';
import { SidebarOverlay, SidebarArea, List, StickyNav } from './style';

const Sidebar = ({ pageData, pathname, toc }) => {
  const [isOpen, setOpen] = useState(false);
  const [width, setWidth] = useState(
    typeof window !== 'undefined' ? window.innerWidth : 992
  );

  const currentPath = pageData.mdx
    ? pageData.mdx.fields.slug
    : pathname.replace(/\/$/, '');

  const onSidebarToggleRequest = useGlobalEvent('sidebartogglerequest');
  onSidebarToggleRequest(() => setOpen(!isOpen));

  useWindowResize(() =>
    setWidth(typeof window !== 'undefined' ? window.innerWidth : 992)
  );

  return (
    <>
      {width < 992 && (
        <Transition
          in={isOpen}
          timeout={{ appear: 0, enter: 250, exit: 250 }}
          appear
          mountOnEnter
          unmountOnExit
        >
          {state => (
            <SidebarOverlay
              isOpen={state === 'entering' || state === 'entered'}
              onClick={() => setOpen(false)}
            >
              <Button>×</Button>
            </SidebarOverlay>
          )}
        </Transition>
      )}
      <Transition in={isOpen || width >= 992} timeout={250}>
        {state => (
          <>
            <SidebarArea isOpen={state === 'entering' || state === 'entered'}>
              <List>
                {toc.map(page => (
                  <Node
                    currentPath={currentPath}
                    page={page}
                    level={1}
                    key={page.path}
                  />
                ))}
              </List>
            </SidebarArea>
            <StickyNav isOpen={state === 'entering' || state === 'entered'}>
              <GetHelp />
              <DarkModeToggle />
            </StickyNav>
          </>
        )}
      </Transition>
    </>
  );
};

export default withToc(Sidebar);
