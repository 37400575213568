import React from 'react';
import { Breadcrumb } from 'gatsby-plugin-breadcrumb';
import { parse as dateParse } from 'date-fns';

import upperCaseFirst from '../../utils/upperCaseFirst';
import { MetaArea, BreadcrumbArea, Meta, MetaSeparator } from './style';

const ArticleTitle = ({ mdx, pageContext }) => {
  const {
    fields: { lastModified, slug },
    frontmatter: { title },
    rawBody,
    timeToRead
  } = mdx;
  const date = false;
  let {
    breadcrumb: { crumbs }
  } = pageContext;

  const words = rawBody
    .replace(/---[\S\s.]+---/, '')
    .trim()
    .split(' ')
    .filter(s => s !== '##' && s !== '###' && s !== '####' && s !== '#####');
  const isStub = words.length < 250;

  const lastModifiedStr = dateParse(
    lastModified,
    'yyyy-MM-dd HH:mm:ss xx',
    new Date()
  ).toLocaleDateString('en-GB');

  const path = slug.split('/');
  crumbs = crumbs.slice(1, crumbs.length - 1);
  const lastSegment = path[path.length - 1];
  const crumbLabel = upperCaseFirst(lastSegment);
  crumbs.push({ pathname: slug, crumbLabel });

  crumbs = crumbs.map(page => ({
    ...page,
    crumbLabel: page.crumbLabel.replace(/-/g, ' ')
  }));

  // Disable all links for crumbs
  const disableAllCrumbs = [];
  for (let i = 1; i <= path.length; i++) {
    disableAllCrumbs.push(`/${path.slice(1, i).join('/')}`);
  }

  return (
    <>
      <MetaArea>
        <BreadcrumbArea>
          <Breadcrumb crumbs={crumbs} disableLinks={disableAllCrumbs} />
        </BreadcrumbArea>
        <Meta>
          {date !== false && lastModified && (
            <span>
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                <path
                  d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm92.49 313l-20 25a16 16 0 01-22.49 2.5l-67-49.72a40 40 0 01-15-31.23V112a16 16 0 0116-16h32a16 16 0 0116 16v144l58 42.5a16 16 0 012.49 22.5z"
                  opacity="0.4"
                ></path>
                <path d="M348.49 321l-20 25a16 16 0 01-22.49 2.5l-67-49.72a40 40 0 01-15-31.23V112a16 16 0 0116-16h32a16 16 0 0116 16v144l58 42.5a16 16 0 012.49 22.5z"></path>
              </svg>
              <time dateTime={lastModified}>{lastModifiedStr}</time>
            </span>
          )}
          {lastModified && timeToRead && !isStub && <MetaSeparator />}
          {timeToRead && !isStub && (
            <span>
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512">
                <path
                  d="M136 272a72 72 0 1072 72 72.08 72.08 0 00-72-72zm304 0a72 72 0 1072 72 72.08 72.08 0 00-72-72z"
                  opacity="0.4"
                ></path>
                <path d="M560.51 225.9L528.75 98.64C522.05 71.78 495 32 443.33 32c-15.63 0-23 2.94-43 9.6a16 16 0 00-10.12 20.24L395.25 77a16 16 0 0015.18 11c3.54 0 4.82-.74 18.23-5.21 26.07-8.68 48.2 6.13 53.53 27.54L511.86 229A134.81 134.81 0 00440 208c-55.09 0-102.27 32.91-123.65 80h-56.7c-21.38-47.09-68.56-80-123.65-80a134.89 134.89 0 00-71.86 21l29.67-118.72c5.32-21.41 27.46-36.22 53.53-27.54C160.76 87.21 162 88 165.57 88a16 16 0 0015.18-11l5.06-15.18a16 16 0 00-10.12-20.24c-20-6.65-27.39-9.59-43-9.59C81 32 54 71.78 47.25 98.64L15.49 225.9C2.16 279.34 0 300.12 0 344a136 136 0 00136 136c72.37 0 131-56.69 135.19-128h33.61c4.2 71.31 62.82 128 135.2 128a136 136 0 00136-136c0-43.88-2.16-64.66-15.49-118.1zM136 416a72 72 0 1172-72 72.08 72.08 0 01-72 72zm304 0a72 72 0 1172-72 72.08 72.08 0 01-72 72z"></path>
              </svg>
              {timeToRead} min read
            </span>
          )}
        </Meta>
      </MetaArea>
      <h1>{title}</h1>
    </>
  );
};

export default ArticleTitle;
