import styled from 'styled-components';

const Button = styled.button`
  padding: 10px 32px;
  background: ${props =>
    typeof props.theme === 'string' ? `var(--${props.theme})` : 'none'};
  border: none;
  border-radius: 8px;
  cursor: pointer;
  color: ${props => (props.theme ? '#fff' : 'inherit')};


  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;

export default Button;
