import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  overflow: auto;
`;

const ResponsiveTable = ({ children }) => (
  <Wrapper>
    <table>{children}</table>
  </Wrapper>
);

export default ResponsiveTable;
