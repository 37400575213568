import React, { useEffect } from 'react';
import { Router } from '@reach/router';

import AuthProvider from '../auth/AuthProvider';
import userManager from '../auth/userManager';
import Spinner from '../components/Spinner';
import { LoadingState } from '../templates/Article/style';

const Callback = () => {
  useEffect(() => {
    userManager
      .signinRedirectCallback()
      .then(() => {
        const redirectUrl = localStorage.getItem('oidc.redirect_url');
        window.location.href = redirectUrl || '/';
      })
      .catch(() => {
        window.location.href = '/';
      });
  }, []);

  return null;
};

const Renew = () => {
  useEffect(() => {
    userManager.signinSilentCallback();
  }, []);

  return null;
};

const AuthTemplate = () => (
  <AuthProvider>
    <Router>
      <Callback path="/auth/callback" />
      <Renew path="/auth/renew" />
    </Router>
    <LoadingState style={{ width: '100%' }}>
      <Spinner />
    </LoadingState>
  </AuthProvider>
);

export default AuthTemplate;
