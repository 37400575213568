import { Children, isValidElement } from 'react';
import unified from 'unified';
import parse from 'remark-parse';
import remark2react from 'remark-react';

const hasChildren = element =>
  isValidElement(element) && Boolean(element.props.children);

const handleTextNode = child => {
  if (
    typeof child === 'undefined' ||
    child === null ||
    typeof child === 'boolean'
  ) {
    return '';
  }

  return child.toString();
};

const getChildText = children => {
  if (!(children instanceof Array) && !isValidElement(children)) {
    return handleTextNode(children);
  } else {
    return Children.toArray(children).reduce((text, child) => {
      let newText;

      if (isValidElement(child) && hasChildren(child)) {
        newText = getChildText(child.props.children);
      } else {
        newText = handleTextNode(child);
      }

      return text.concat(`\n\n${newText}`);
    }, '');
  }
};

const reactChildrenToMarkdown = children => {
  const markdown = getChildText(children);

  return unified()
    .use(parse)
    .use(remark2react)
    .processSync(markdown).contents;
};

export default reactChildrenToMarkdown;
