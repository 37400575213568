import React from 'react';

import headingSlug from '../../utils/headingSlug';
import {
  FixedContainer,
  Headings,
  HeadingList,
  HeadingItem,
  HeadingLink
} from './style';

const InThisArticle = ({ headings }) => {
  const handleClick = e => {
    const event = new CustomEvent('headingshashchange', {
      detail: { hash: e.target.getAttribute('href') }
    });
    window.dispatchEvent(event);
  };

  return (
    <FixedContainer>
      <Headings id="headings">
        <h5>In this article</h5>
        <HeadingList>
          {headings.map(heading => {
            let headingValue = heading.value;
            if (typeof document !== 'undefined') {
              const tmp = document.createElement('div');
              tmp.innerHTML = headingValue;
              headingValue = tmp.textContent;
            }
            const hash = headingSlug(headingValue);

            return (
              <HeadingItem key={hash}>
                <HeadingLink href={`#${hash}`} onClick={handleClick}>
                  {headingValue}
                </HeadingLink>
              </HeadingItem>
            );
          })}
        </HeadingList>
      </Headings>
    </FixedContainer>
  );
};

export default InThisArticle;
