import Alert from '../../components/MDXComponents/Alert';
import CodeBlockWrap from '../../components/MDXComponents/CodeBlockWrap';
import { CodeBlockMarkdownShim } from '../../components/MDXComponents/CodeBlock';
import Collapse from '../../components/MDXComponents/Collapse';
import HttpRequest from '../../components/MDXComponents/HttpRequest';
import HttpResponse from '../../components/MDXComponents/HttpResponse';
import LanguageTabs from '../../components/MDXComponents/LanguageTabs';
import {
  PageNavigation,
  PageNavigationItem
} from '../../components/MDXComponents/PageNavigation';
import ResponsiveTable from '../../components/MDXComponents/ResponsiveTable';
import { Tab, Tabs } from '../../components/MDXComponents/Tabs';

const components = {
  Alert,
  CodeBlockWrap,
  Collapse,
  HttpRequest,
  HttpResponse,
  LanguageTabs,
  PageNavigation,
  PageNavigationItem,
  pre: CodeBlockMarkdownShim,
  table: ResponsiveTable,
  Tab,
  Tabs
};

export default components;
