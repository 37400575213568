import styled from 'styled-components';

export const ErrorArea = styled.div`
  width: 100%;
  padding: 100px 0;
  text-align: center;

  h2 {
    margin-top: 0;
    font-size: 2.5rem;
  }
`;
