import React from 'react';

import { Stack, Notification, App, AppIcon } from './style';

const Notifications = () => (
  <Stack>
    <Notification>
      <App>
        <AppIcon />
        Yourbrand
      </App>
      <h6>
        New Stock Alert{' '}
        <span role="img" aria-label="siren emoji">
          🚨
        </span>
      </h6>
      <p>A product you saved is now back in stock!</p>
    </Notification>
    <Notification>
      <App>
        <AppIcon />
        Yourbrand
      </App>
      <h6>
        As a member you can save 30%{' '}
        <span role="img" aria-label="dress emoji">
          👗
        </span>
      </h6>
      <p>Use code MEMBERSONLY</p>
    </Notification>
    <Notification>
      <App>
        <AppIcon />
        Yourbrand
      </App>
      <h6>
        Hey beautiful, forgetting something?{' '}
        <span role="img" aria-label="bag emoji">
          🛍
        </span>
      </h6>
      <p>You left items in your bag, want to finish?</p>
    </Notification>
  </Stack>
);

export default Notifications;
