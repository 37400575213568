import styled from 'styled-components';

export const Phone = styled.div`
  position: absolute;
  top: 120px;
  left: -48px;
  width: 214px;
  height: 426px;
  transform: rotate(-2deg);

  @media (min-width: 768px) {
    top: 120px;
    left: 150px;
  }

  @media (min-width: 992px) {
    top: 180px;
    left: -280px;
  }

  @media (min-width: 1280px) {
    top: 350px;
    left: initial;
    right: 450px;
  }
`;

export const PhoneFrame = styled.div`
  position: absolute;
  top: -16px;
  left: -16px;
  right: -16px;
  bottom: -16px;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 3px 18px 0px inset,
    rgba(0, 0, 0, 0.1) 0px 12px 48px 0px,
    rgba(255, 255, 255, 0.04) 0px 2px 3px 0px inset;
  border-radius: 38px;
  background: #18154d;
`;

export const PhoneScreen = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: hidden;
  border-radius: 26px;
  background: linear-gradient(40deg, #00d4ff, #d0f5f5);
`;

export const Pdp = styled.div`
  position: relative;
  overflow: hidden;
  margin: 8px;
  padding: 46% 0;
  border-radius: 20px;
  background: rgba(27, 24, 87, 0.07);

  svg {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    padding: 32px;
    animation: flipInOut 12s infinite cubic-bezier(1, 0, 0, 1);
    will-change: opacity, transform;
    opacity: 0;
  }

  svg:nth-child(1) {
    width: 110%;
  }

  svg:nth-child(2) {
    animation-delay: 3s;
  }

  svg:nth-child(3) {
    top: 48%;
    animation-delay: 6s;
  }

  svg:nth-child(4) {
    animation-delay: 9s;
  }

  @keyframes flipInOut {
    0% {
      opacity: 1;
      transform: translate(-50%, -50%) translateX(150%);
    }
    25% {
      opacity: 1;
      transform: translate(-50%, -50%);
    }
    50% {
      opacity: 1;
      transform: translate(-50%, -50%) translateX(-150%);
    }
    100% {
      opacity: 1;
      transform: translate(-50%, -50%) translateX(-150%);
    }
  }
`;

export const Review = styled.div`
  display: flex;
  width: 75%;
  padding: 8px 16px;

  svg {
    margin-right: 4px;
    animation: fadeIn 2s infinite;
    will-change: opacity, transform;
    opacity: 0;
  }

  svg:nth-child(2) {
    animation-delay: 0.1s;
  }

  svg:nth-child(3) {
    animation-delay: 0.2s;
  }

  svg:nth-child(3) {
    animation-delay: 0.3s;
  }

  svg:nth-child(4) {
    animation-delay: 0.4s;
  }

  svg:nth-child(5) {
    animation-delay: 0.5s;
  }

  @keyframes fadeIn {
    0% {
      opacity: 0;
      transform: scale(0.8);
    }
    10% {
      opacity: 0;
      transform: scale(0.8);
    }
    50% {
      opacity: 1;
      transform: scale(1);
    }
    85% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
`;

export const Modular = styled.div`
  position: relative;
`;

export const ClickCollect = styled.div`
  display: flex;
  align-items: center;
  margin-top: 8px;
  padding: 16px;
  background: rgba(255, 255, 255, 0.2);

  svg {
    width: 24px;
    height: 24px;
  }
`;

export const ClickCollectSkeleton = styled.div`
  flex: 1;
  height: 10px;
  border-radius: 4px;
  background: rgba(255, 255, 255, 0.4);

  &:first-child {
    margin-right: 16px;
  }

  &:last-child {
    margin-left: 16px;
  }
`;

export const OutOfStock = styled.div`
  display: flex;
  align-items: center;
  margin-top: 8px;
  padding: 16px;
  background: rgba(255, 255, 255, 0.2);
`;

export const NotifyMeButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 24px;
  border-radius: 4px;
  background: rgba(255, 255, 255, 0.2);

  svg {
    width: 16px;
    height: 16px;
  }
`;

export const Description = styled.div`
  display: grid;
  height: 100px;
  margin-top: 8px;
  padding: 16px;
  background: rgba(255, 255, 255, 0.2);
`;
