import styled from 'styled-components';

export const SupportSection = styled.section`
  display: flex;
  align-items: center;
  height: 600px;
  background: url(https://assets.poq.io/docs/jacket-mockup.jpg) no-repeat;
  background-size: cover;
  text-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
  color: #fff;

  h2 {
    margin-top: 0;
    font-size: 3rem;
  }

  p {
    padding: 0 16px;
    margin-bottom: 20px;
    font-size: 1.7rem;

    @media (min-width: 992px) {
      padding: 0;
    }
  }

  a {
    margin: 0 16px;
    padding: 10px 32px;
    border: none;
    border-radius: 4px;
    background: var(--primary);
    font-size: 1.1rem;
    text-shadow: none;
    color: #fff;

    @media (min-width: 992px) {
      margin: 0;
    }
  }
`;

/* eslint-disable-next-line */
export default () => {};
