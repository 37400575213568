import React from 'react';
import Svg from 'react-inlinesvg';

import { List, ListItem, ListLink, LinkChevron } from './style';
import ChevronRight from './assets/chevron-right.svg';

const Node = ({
  currentPath,
  level = 1,
  listExpanded,
  page: { children, icon, path, title },
  ...props
}) => {
  const isCurrentPage = currentPath === path;

  let pathMatches = true;
  if (path) {
  const articlePath = path.split('/').slice(1);
  const currentArticlePath = currentPath.split('/').slice(1);
    for (let i = 0; i < level; i++) {
      pathMatches = pathMatches && currentArticlePath[i] === articlePath[i];
    }
  }
  const expanded = pathMatches || listExpanded;

  return (
    <ListItem level={level} expanded={expanded}>
      <ListLink
        to={path || '#'}
        active={isCurrentPage ? 1 : 0}
        level={level}
        {...props}
      >
        {icon && <Svg src={`/page-icons/${icon}`} />}
        {title || 'Untitled page'}
        {!pathMatches && children && (
          <LinkChevron>
            <ChevronRight />
          </LinkChevron>
        )}
      </ListLink>
      {children && (
        <List level={level + 1}>
          {children.map(page => (
            <Node
              currentPath={currentPath}
              page={page}
              level={level + 1}
              key={page.path}
              listExpanded={pathMatches}
            />
          ))}
        </List>
      )}
    </ListItem>
  );
};

export default Node;
