import React, { useContext } from 'react';
import styled from 'styled-components';

import userManager from '../../auth/userManager';
import { AuthContext } from '../../auth/AuthProvider';
import Button from '../Button';

const UserArea = styled.div`
  a {
    display: flex;
    align-items: center;
  }

  @media (min-width: 768px) {
    padding-left: 16px;
  }
`;

const SignInButton = styled(Button)`
  display: flex;
  align-items: center;
  font-size: 0;
  color: inherit;

  svg {
    margin-right: 0;
  }

  @media (max-width: 767px) {
    padding-left: 16px;
    padding-right: 0;
  }

  @media (min-width: 768px) {
    font-size: inherit;

    svg {
      margin-right: 6px;
    }
  }
`;

const AppManagerLogo = styled.span`
  @media (max-width: 767px) {
    padding-left: 16px;
  }

  @media (min-width: 768px) {
    margin-right: 4px;
  }
`;

const AppManagerText = styled.span`
  display: none;
  color: ${props => (props.isHomepage ? '#fff' : 'inherit')};
  transition: color 0.2s ease-in-out;

  @media (min-width: 768px) {
    display: flex;
    align-items: center;
  }

  svg {
    margin-left: 6px;
  }
`;

const AuthUser = ({ isHomepage }) => {
  const user = useContext(AuthContext);

  return (
    <UserArea>
      {user ? (
        <a href="https://appmanager.poq.io" aria-label="Go to App Manager">
          <AppManagerLogo>
            <svg
              width="28"
              height="28"
              viewBox="0 0 113 130"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill="#633686"
                d="M28.713 48.5L57.292 32 85.87 48.5 56.541 64.408z"
              />
              <path fill="#3A204F" d="M85.87 48.5v33L57.292 98l-.75-33.592z" />
              <path fill="#8F58BB" d="M28.713 48.5v33L57.292 98l-.75-33.592z" />
            </svg>
          </AppManagerLogo>
          <AppManagerText isHomepage={isHomepage}>
            App Manager{' '}
            <svg
              width="12"
              height="12"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 448 512"
            >
              <path d="M190.5 66.9l22.2-22.2c9.4-9.4 24.6-9.4 33.9 0L441 239c9.4 9.4 9.4 24.6 0 33.9L246.6 467.3c-9.4 9.4-24.6 9.4-33.9 0l-22.2-22.2c-9.5-9.5-9.3-25 .4-34.3L311.4 296H24c-13.3 0-24-10.7-24-24v-32c0-13.3 10.7-24 24-24h287.4L190.9 101.2c-9.8-9.3-10-24.8-.4-34.3z"></path>
            </svg>
          </AppManagerText>
        </a>
      ) : (
        <SignInButton onClick={() => userManager.signinRedirect()}>
          <svg
            width="28"
            height="28"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 496 512"
          >
            <path
              d="M248 8C111 8 0 119 0 256s111 248 248 248 248-111 248-248S385 8 248 8zm0 96a88 88 0 11-88 88 88 88 0 0188-88zm0 344a191.61 191.61 0 01-146.5-68.2c18.8-35.4 55.6-59.8 98.5-59.8a24.76 24.76 0 017.1 1.1 124.67 124.67 0 0081.8 0 24.76 24.76 0 017.1-1.1c42.9 0 79.7 24.4 98.5 59.8A191.61 191.61 0 01248 448z"
              opacity="0.4"
            ></path>
            <path d="M248 280a88 88 0 10-88-88 88 88 0 0088 88zm48 40a24.76 24.76 0 00-7.1 1.1 124.67 124.67 0 01-81.8 0 24.76 24.76 0 00-7.1-1.1c-42.9 0-79.7 24.4-98.5 59.8 68.07 80.91 188.84 91.32 269.75 23.25a192 192 0 0023.25-23.25C375.7 344.4 338.9 320 296 320z"></path>
          </svg>
          Sign in
        </SignInButton>
      )}
    </UserArea>
  );
};

export default AuthUser;
