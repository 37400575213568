import styled, { css } from 'styled-components';
import { Link } from 'gatsby';

import Container from '../Container';

export const GuidesSection = styled.section`
  margin-bottom: 64px;
  background: ${props => (props.theme.isDark ? props.theme.bg : '#fbfbfb')};
  transition: background 0.2s ease-in-out;
`;

export const GuidesContainer = styled(Container)`
  display: flex;
  flex-wrap: wrap;
`;

const linkStyles = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  overflow: hidden;
  width: 100%;
  padding: 60px 30px;
  text-align: center;
  text-decoration: none;
  color: inherit;
  transition: all 0.2s ease-in-out;

  &:hover,
  &:focus {
    box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.15);
    background: ${props => (props.theme.isDark ? '#111' : '#fff')};
    transform: scale(1.075);
  }

  @media (min-width: 450px) {
    width: 50%;
  }
  @media (min-width: 992px) {
    width: 33%;
  }
`;

export const GuideLink = styled(Link)`
  ${linkStyles};
`;

export const GuideHref = styled.a`
  ${linkStyles};
`;

export const Icon = styled.img`
  width: 100px;
  height: 100px;
  max-width: none;
  filter: ${props => (props.theme.isDark ? 'brightness(10)' : 'none')};
  transition: filter 0.2s ease-in-out;
`;

export const GuideContent = styled.div`
  flex: 1;
  padding: 12px 0;

  h3 {
    margin: 16px 0 10px;
    font-size: 1.5rem;
    border: none;
  }

  p {
    margin-bottom: 0;
  }
`;

/* eslint-disable-next-line */
export default () => {};
