import styled from 'styled-components';

import { navbarHeight, footerHeight } from './constants';

export const Main = styled.main`
  display: flex;
  align-items: stretch;
  min-height: calc(100vh - ${footerHeight}px);

  @media (min-width: 992px) {
    min-height: calc(100vh - ${navbarHeight + footerHeight}px);
    margin-top: ${navbarHeight}px;
  }
`;
