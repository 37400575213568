import styled from 'styled-components';

export const LanguageTabsArea = styled.div`
  .react-tabs__tab-list {
    display: flex;
    align-items: center;
    margin: 0;
    border: none;
    background: #3c4257;
    border-radius: 4px 4px 0 0;
    padding: 8px 16px;
  }

  .react-tabs__tab {
    padding: 0 12px 0 0;
    border: none;
    border-radius: 0;
    background: none;
    font-size: 0.9rem;
    color: #a8b1bd;
  }

  .react-tabs__tab--selected {
    color: var(--info);
  }

  .react-tabs__tab:focus:after {
    display: none;
  }

  pre {
    border-radius: 0 0 4px 4px !important;
    margin-top: 0;
  }
`;
