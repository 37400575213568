import styled from 'styled-components';

import { navbarHeight, postWidth } from '../../components/Layout/constants';

export const LoadingState = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-height: calc(100vh - ${navbarHeight}px);

  @media (min-width: 1090px) {
    width: ${postWidth}px;
  }
`;

export const PostContent = styled.div`
  flex: 1 1 auto;
  display: flex;
  width: 100%;
  padding: 24px;

  @media (min-width: 992px) {
    padding: 56px;
  }
`;

export const BreadcrumbArea = styled.div`
  flex: 1;
`;

export const Meta = styled.p`
  margin: 0;
  color: ${props =>
    props.theme.isDark ? 'rgba(255, 255, 255, 0.5)' : 'rgba(0, 0, 0, 0.54)'};
  transition: color 0.2s ease-in-out;

  svg {
    display: inline-block;
    position: relative;
    top: -1px;
    width: 13px;
    height: 13px;
    margin-right: 5px;
    vertical-align: middle;
  }
`;

export const MetaSeparator = styled.span`
  display: inline-block;
  width: 12px;
`;

export const Article = styled.article`
  width: 100%;
  margin-bottom: 115px;

  @media (min-width: 1090px) {
    width: ${postWidth}px;
  }

  mark {
    background-color: ${props =>
      props.theme.isDark ? 'rgba(255, 255, 0, 0.2)' : '#fcf8e3'};
    ${props => props.theme.isDark && 'color: inherit;'};
  }

  h1 {
    margin-top: 4px;
  }

  h1 mark {
    background-color: initial;
    color: inherit;
  }

  li,
  p {
    line-height: 1.625;
  }

  // Style the gatsby-remark-autolink-headers anchor
  .anchor {
    top: 6px;
    padding: 4px 8px 4px 4px;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    &:hover .anchor {
      display: block;
    }
  }

  iframe {
    max-width: 100%;
  }

  blockquote {
    margin: 0 0 20px;
    padding: 10px 20px;
    border-left: 5px solid ${props => (props.theme.isDark ? '#333' : '#eee')};
    font-size: 17.5px;
    transition: border-color 0.2s ease-in-out;
  }

  pre {
    overflow: auto;
    border-radius: 4px;
    background: ${props => (props.theme.isDark ? '#2b2e35' : '#545c71')};
    transition: background 0.2s ease-in-out;
  }

  code {
    font-family: 'Menlo', 'Monaco', 'Consolas', 'Courier New', monospace;
    margin: 0 1px;
    padding: 3px 6px;
    border-radius: 2px;
    background-color: ${props => (props.theme.isDark ? '#232323' : '#f5f7f9')};
    font-size: 85%;
    transition: background 0.2s ease-in-out;
  }

  pre code {
    display: block;
    margin: 0;
    padding: 12px 14px 12px 24px;
    border-radius: 0;
    background: none;
    word-wrap: normal;
  }

  /**
   * prism-react-renderer oceanicNext theme seems to not support .atrule in YAML
   * This rule is from https://github.com/blackxored/prism-oceanic-next/blob/master/src/oceanic-next.css
   */
  pre.language-yml .atrule,
  pre.language-yaml .atrule {
    color: #5fb3b3;
  }

  .gatsby-resp-image-link,
  p > img:only-child {
    cursor: zoom-in;
  }

  .gatsby-resp-image-figure {
    margin: 0;
    text-align: center;
  }

  .gatsby-resp-image-wrapper {
    display: flex !important;
    justify-content: center;
  }

  li .gatsby-resp-image-wrapper {
    margin: 16px 0;
  }

  .gatsby-resp-image-background-image {
    display: none !important;
  }

  .gatsby-resp-image-image,
  p > img:only-child {
    position: static !important;
    display: block;
    width: auto !important;
    height: auto !important;
    max-width: 100%;
    max-height: 60vh;
  }

  .gatsby-resp-image-figcaption {
    padding: 8px 0;
    color: #6b7885;
  }

  .gatsby-highlight {
    overflow: hidden;
    border-radius: 4px;

    pre {
      margin: 0;
    }
  }

  .embedVideo-container {
    padding: 16px 100px;
  }
  .embedVideo-iframe {
    width: 100%;

    @media (min-width: 992px) {
      height: 310px;
    }
  }
`;

export const MetaArea = styled.div`
  display: flex;
  justify-content: space-between;
  font-size: 0.7rem;
  text-transform: uppercase;

  div {
    display: inline-block;
  }

  p {
    line-height: initial !important;
  }

  .breadcrumb__list {
    margin: 0;
    padding: 0;
    list-style: none;
  }

  .breadcrumb__list__item {
    display: inline-block;
  }

  .breadcrumb__separator {
    padding: 0 8px;
    color: #ccc;
  }

  mark {
    background-color: initial;
    color: inherit;
  }
`;
