import styled from 'styled-components';
import { Link } from 'gatsby';

import { navbarHeight } from '../../../components/Layout/constants';
import Container from '../Container';

export const HeroArea = styled.section`
  position: relative;
  height: 100vh;
  min-height: 700px;
  overflow: hidden;
  padding: ${navbarHeight + 32}px 0 ${120 - navbarHeight}px;
  margin-top: -${navbarHeight}px;
  background-color: #1b1857;
  color: #fff;

  h2 {
    margin: 0;
    padding: 0 32px;
    font-size: 2.5rem;

    @media (min-width: 992px) {
      padding: 0;
    }

    @media (min-width: 1280px) {
      padding-top: max(150px, 20vh);
      font-size: 3.5rem;
    }
  }
`;

export const HeroContainer = styled(Container)`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
`;

export const HeroColumn = styled.div`
  z-index: 1;
  width: 100%;

  @media (min-width: 768px) {
    width: 50%;
  }
`;

export const Cta = styled(Link)`
  display: inline-flex;
  align-items: center;
  margin: 32px 32px 0;
  font-size: 1.5rem;
  color: #00d4ff;
  transition: opacity 0.2s;

  &:hover,
  &:focus {
    opacity: 0.7;
  }

  @media (min-width: 992px) {
    margin-left: 0;
    margin-right: 0;
  }

  svg {
    display: inline-block;
    position: relative;
    top: -2px;
    margin-left: 12px;
    width: 20px;
    height: 20px;
  }
`;

export const DeviceArea = styled.div`
  width: 100%;
  transform: scale(0.6);

  @media (min-width: 768px) {
    transform: scale(0.8);
  }

  @media (min-width: 992px) {
    width: 50%;
  }

  @media (min-width: 1280px) {
    width: initial;
    transform: none;
  }
`;

export const AppManager = styled.div`
  display: none;
  position: absolute;
  transform: rotate(2deg);
  padding: 16px;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 3px 18px 0px inset,
    rgba(0, 0, 0, 0.1) 0px 12px 48px 0px,
    rgba(255, 255, 255, 0.04) 0px 2px 3px 0px inset;
  border-radius: 38px;
  background: #18154d;

  @media (min-width: 768px) {
    display: block;
    right: -140px;
    bottom: -560px;
    width: 560px;
  }

  @media (min-width: 992px) {
    bottom: -700px;
  }

  @media (min-width: 1280px) {
    right: -64px;
    bottom: -60px;
    width: 430px;
  }

  img {
    width: 100%;
    border-radius: 22px;
  }
`;
