import React from 'react';

import Layout from '../components/Layout';
import Seo from '../components/Seo';
import ErrorPage from '../containers/Error';

const NotFoundPage = ({ pathname = '/404', title = 'Page not found' }) => (
  <Layout pathname={pathname}>
    <Seo title={title} />
    <ErrorPage
      title="Page not found"
      errorMessage="Oops! The page you are looking for has been removed or relocated."
    />
  </Layout>
);

export default NotFoundPage;
