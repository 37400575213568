import React from 'react';

import ProductScreen from './ProductScreen';
import LoyaltyScreen from './LoyaltyScreen';
import Notification from './Notification';
import { DeviceArea, AppManager } from './style';

const Devices = () => (
  <DeviceArea>
    <ProductScreen />
    <LoyaltyScreen />
    <Notification />
    <AppManager>
      <img src="https://i.imgur.com/l4Ml6Hz.png" alt="" />
    </AppManager>
  </DeviceArea>
);

export default Devices;
