import React, { useEffect, useMemo, useRef } from 'react';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import { MDXProvider } from '@mdx-js/react';
import { graphql } from 'gatsby';
import SmoothScroll from 'smooth-scroll';
import Gumshoe from 'gumshoejs';
import GLightbox from 'glightbox';
import Mark from 'mark.js';
import queryString from 'query-string';

import AuthProvider from '../../auth/AuthProvider';
import Layout from '../../components/Layout';
import { navbarHeight } from '../../components/Layout/constants';
import Seo from '../../components/Seo';
import Sidebar from '../../components/Sidebar';
import ArticleTitle from './ArticleTitle';
import InThisArticle from '../../components/InThisArticle';
import Spinner from '../../components/Spinner';
import { PostContent, Article, LoadingState } from './style';
import components from './components';

const scrollOffset = () => (window.innerWidth > 992 ? navbarHeight + 20 : 20);

const ArticleTemplate = ({
  data,
  isLoading = false,
  isPrivate,
  location,
  pageContext,
  pathname,
  title: fallbackTitle
}) => {
  const articleRef = useRef();

  const { mdx = { fields: {}, frontmatter: {} } } = data;
  const {
    body,
    excerpt,
    fields: { slug = null },
    frontmatter: { excerpt: excerptOverride, published, title = null },
    headings
  } = mdx;

  const topHeadings = useMemo(
    () => (headings ? headings.filter(h => h.depth < 3) : []),
    [headings]
  );

  // Set up smooth scroll
  useEffect(() => {
    if (!isLoading) {
      const scroll = new SmoothScroll('a[href*="#"]', {
        offset: scrollOffset,
        speedAsDuration: true
      });

      return () => scroll.destroy();
    }
  }, [isLoading]);

  // Set up scroll spy
  useEffect(() => {
    if (!isLoading) {
      if (topHeadings.length > 0) {
        const spy = new Gumshoe('#headings a', {
          offset: scrollOffset
        });

        return () => spy.destroy();
      }
    }
  }, [topHeadings, isLoading]);

  // Highlight search query terms
  useEffect(() => {
    if (typeof window !== 'undefined') {
      const { query } = queryString.parse(location.search);
      if (query) {
        const mark = new Mark(articleRef.current);
        window.markJs = mark;
        mark.mark(query, { exclude: ['code', 'pre', '.token'] });
      }
    }
  }, [location.search]);

  // Run onRouteUpdate for private articles
  useEffect(() => {
    if (isPrivate && !isLoading) {
      window.setTimeout(window.gatsbyOnRouteUpdate, 1);
    }
  }, [isPrivate, isLoading]);

  // Enable lightbox for gatsby-remark-images and vanilla HTML img
  // (SVGs and GIFs aren't processed by gatsby-remark-images)
  useEffect(() => {
    GLightbox({ selector: '.gatsby-resp-image-link' });
    GLightbox({ selector: 'article p > img' });
  }, []);

  // Add robots noindex to articles that are marked as published = false
  const noindex = published === false;

  return (
    <AuthProvider>
      <Layout pathname={slug || pathname}>
        <Seo
          title={title || fallbackTitle}
          description={excerptOverride || excerpt}
          meta={noindex ? [{ name: 'robots', value: 'noindex' }] : []}
        />
        <Sidebar pageData={data} pathname={pathname} />
        {isLoading ? (
          <LoadingState>
            <Spinner />
          </LoadingState>
        ) : (
          <PostContent>
            <Article ref={articleRef}>
              <ArticleTitle mdx={mdx} pageContext={pageContext} />
              <MDXProvider components={components}>
                <MDXRenderer>{body}</MDXRenderer>
              </MDXProvider>
            </Article>
            {topHeadings.length > 0 && <InThisArticle headings={topHeadings} />}
          </PostContent>
        )}
      </Layout>
    </AuthProvider>
  );
};

export const pageQuery = graphql`
  query($id: String!) {
    mdx(fields: { id: { eq: $id } }) {
      body
      excerpt
      fields {
        lastModified
        slug
      }
      frontmatter {
        date
        excerpt
        published
        title
      }
      headings {
        depth
        value
      }
      rawBody
      timeToRead
    }
  }
`;

export default ArticleTemplate;
