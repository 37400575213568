import styled from 'styled-components';

import { navbarHeight, sidebarWidth } from '../Layout/constants';

export const Navbar = styled.header`
  position: relative;
  z-index: 10;
  justify-content: space-between;
  height: ${navbarHeight}px;
  padding: 16px 24px;
  border-bottom: 1px solid
    ${props =>
      props.isHomepage
        ? 'transparent'
        : props.theme.isDark
        ? '#333'
        : '#d4dadf'};
  box-shadow: 0 3px 8px 0
    ${props =>
      props.isHomepage
        ? 'transparent'
        : props.theme.isDark
        ? 'rgba(255, 255, 255, 0.03)'
        : 'rgba(116, 129, 141, 0.1)'};
  background: ${props => (props.isHomepage ? 'none' : props.theme.bg)};
  color: ${props => (props.isHomepage ? '#fff' : props.theme.fg)};
  transition: all 0.2s ease-in-out;

  @media (min-width: 992px) {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1000;
  }

  .container {
    display: flex;
    align-items: center;
    max-width: 1448px;
    margin: 0 auto;
  }
`;

export const NavbarLeft = styled.div`
  flex: 1;
  display: flex;
  align-items: center;

  @media (min-width: 1200px) {
    padding-left: 24px;
  }
`;

export const Logo = styled.div`
  display: flex;
  align-items: center;
  width: 82px;
  font-size: 0;
  transition: border-color 0.2s ease-in-out;

  @media (min-width: 992px) {
    width: ${sidebarWidth - 24}px;
    border-right: 1px solid
      ${props =>
        props.isHomepage
          ? 'transparent'
          : props.theme.isDark
          ? '#444'
          : '#e6ecf1'};
  }

  a {
    display: flex;
    align-items: center;
    text-decoration: none;
    color: inherit;
  }

  @media (min-width: 992px) {
    width: calc((100% - 1448px) / 2 + ${sidebarWidth + 58}px);
    min-width: ${sidebarWidth - 24}px;
    font-size: 0.8rem;
  }
`;

export const DocsBadge = styled.span`
  display: none;
  margin-left: 12px;
  padding: 2px 6px;
  border-radius: 4px;
  background: rgba(125, 66, 184, 0.3);
  font-weight: bold;
  text-transform: uppercase;
  color: ${props => (props.isHomepage ? '#fff' : 'var(--primary)')};
  transition: color 0.2s ease-in-out;

  @media (min-width: 992px) {
    display: inline-block;
  }
`;

export const NavbarRight = styled.div`
  display: flex;
  align-items: center;
  height: 48px;
  padding-left: 8px;
`;
