import React, { useCallback, useEffect, useRef, useState } from 'react';
import ReactDOMServer from 'react-dom/server';
import { navigate } from 'gatsby';
import { DocSearchModal, useDocSearchKeyboardEvents } from '@docsearch/react';
import '@docsearch/css';

import upperCaseFirst from '../../utils/upperCaseFirst';
import NewStartScreen from './NewStartScreen';
import Hit, { HitSelectedStyles } from './Hit';
import { SearchArea, SearchButton } from './style';

const FADE_DURATION = 120;

const Search = ({ isHomepage }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [initialQuery, setInitialQuery] = useState(undefined);
  const searchButtonRef = useRef(null);
  // Only check the platform if we are rendering in browser
  let isMobile = false;
  let macOs = true;
  if (typeof window !== 'undefined') {
    const userAgent = window.navigator.userAgent;
    isMobile = /Android|webOS|iPhone|iPad|iPod|Opera Mini/i.test(userAgent);
    macOs = userAgent.toLowerCase().indexOf('mac os') > -1;
  }
  const keyboardNavigator = {
    navigate({ item }) {
      navigate(item.pathname);
    }
  };

  const onOpen = useCallback(() => {
    setIsOpen(true);
  }, [setIsOpen]);

  const onClose = useCallback(() => {
    const modal = document.querySelector('.DocSearch-Container');
    if (modal) {
      // fade out transition
      modal.style.opacity = 0;
      setTimeout(() => {
        setIsOpen(false);
      }, FADE_DURATION);
    }
  }, [setIsOpen]);

  const onInput = React.useCallback(
    event => {
      setIsOpen(true);
      setInitialQuery(event.key);
    },
    [setIsOpen, setInitialQuery]
  );

  useDocSearchKeyboardEvents({
    isOpen,
    onOpen,
    onClose,
    onInput,
    searchButtonRef
  });

  useEffect(() => {
    const addStartScreen = () => {
      const dropDown = document.querySelector('.DocSearch-Dropdown');
      const isExisting = document.querySelector('.DocSearch-NewStartScreen');
      if (dropDown && !isExisting) {
        dropDown.insertAdjacentHTML(
          'beforeend',
          ReactDOMServer.renderToStaticMarkup(<NewStartScreen />)
        );
      }
    };

    if (isOpen) {
      const modal = document.querySelector('.DocSearch-Container');
      const searchInput = document.querySelector('.DocSearch-Input');
      if (modal) {
        modal.style.opacity = 1;
        addStartScreen();
      }
      if (searchInput) {
        const handleInput = event => {
          const newStartScreen = document.querySelector(
            '.DocSearch-NewStartScreen'
          );
          if (newStartScreen) {
            newStartScreen.style.display =
              event.target.value !== '' ? 'none' : 'grid';
          }
        };
        searchInput.addEventListener('input', handleInput);
        return () => {
          searchInput.removeEventListener('input', handleInput);
        };
      }
    }
  }, [isOpen]);

  return (
    <SearchArea>
      <HitSelectedStyles />
      <SearchButton
        onClick={onOpen}
        ref={searchButtonRef}
        isHomepage={isHomepage}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
        >
          <path
            d="M15.5 14h-.79l-.28-.27C15.41 12.59 16 11.11 16 9.5 16 5.91 13.09 3 9.5 3S3 5.91 3 9.5 5.91 16 9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14z"
            fill="#cfd4d8"
          ></path>
        </svg>
        <span>Search</span>
        {!isMobile && <kbd>{macOs ? '⌘' : 'Ctrl+'}K</kbd>}
      </SearchButton>
      {isOpen && (
        <DocSearchModal
          initialQuery={initialQuery}
          appId={process.env.GATSBY_DOCSEARCH_APP_ID.toUpperCase()}
          apiKey={process.env.GATSBY_DOCSEARCH_API_KEY}
          indexName="poqcommerce"
          searchParameters={{
            analytics: process.env.NODE_ENV !== 'development'
          }}
          transformItems={items =>
            items.map(item => {
              if (process.env.NODE_ENV === 'development') {
                item.url = item.url.replace(
                  'docs.poqcommerce.com',
                  window.location.host
                );
                item.url = item.url.replace('https:', window.location.protocol);
              }

              // Uppercase our sections
              const sections = ['guides', 'integrations', 'developers'];
              if (sections.includes(item.hierarchy.lvl0)) {
                item.hierarchy.lvl0 = upperCaseFirst(item.hierarchy.lvl0);
              }

              const hierarchyLvl0Value = item._highlightResult.hierarchy?.lvl0.value;

              let foundSection;

              if (hierarchyLvl0Value && (foundSection = sections.find(section => hierarchyLvl0Value.includes(section)))) {
                item._highlightResult.hierarchy.lvl0.value = hierarchyLvl0Value.replace(
                  foundSection,
                  upperCaseFirst(foundSection)
                );
              }

              const hierarchyCamel = item._highlightResult.hierarchy_camel;
              if (hierarchyCamel.length > 0) {
                const hierarchyCamelLvl0Value = hierarchyCamel[0].lvl0.value;
                if (
                  (foundSection = sections.find(
                    section => hierarchyCamelLvl0Value.indexOf(section) > -1
                  ))
                ) {
                  item._highlightResult.hierarchy_camel[0].lvl0.value = hierarchyCamelLvl0Value.replace(
                    foundSection,
                    upperCaseFirst(foundSection)
                  );
                }
              }

              return item;
            })
          }
          hitComponent={Hit}
          initialScrollY={
            typeof window !== 'undefined' ? window.scrollY : undefined
          }
          onClose={onClose}
          navigator={keyboardNavigator}
        />
      )}
    </SearchArea>
  );
};

export default Search;
