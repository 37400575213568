import React from 'react';

import AuthProvider from '../auth/AuthProvider';
import Layout from '../components/Layout';
import Seo from '../components/Seo';
import Homepage from '../containers/Homepage';
import Hero from '../containers/Homepage/Hero';
import Guides from '../containers/Homepage/Guides';
import WhatsNew from '../containers/Homepage/WhatsNew';
import GetSupport from '../containers/Homepage/GetSupport';

const IndexPage = () => (
  <AuthProvider>
    <Layout isHomepage pathname="/">
      <Seo meta={[{ name: 'og:image', content: '/og/hero.jpg' }]} />
      <Homepage>
        <Hero />
        <Guides />
        <WhatsNew />
        <GetSupport />
      </Homepage>
    </Layout>
  </AuthProvider>
);

export default IndexPage;
