import styled from 'styled-components';
import { Link } from 'gatsby';

export const BottomNavigationBar = styled.nav`
  position: fixed;
  z-index: 5;
  bottom: 0;
  left: 0;
  right: 0;
  border-top: 1px solid ${props => (props.theme.isDark ? '#333' : '#d4dadf')};
  background: ${props => props.theme.bg};
  color: ${props => props.theme.fg};
  transition: background 0.2s ease-in-out;

  @media (min-width: 992px) {
    display: none;
  }

  svg {
    width: 28px;
    height: 28px;
  }
`;

export const BottomNavigationLink = styled(Link)`
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  padding: 8px;
  color: ${props => (props.active ? 'var(--primary)' : 'inherit')};

  &:before {
    height: 2px;
    width: 90%;
    position: absolute;
    border-bottom-left-radius: 2px;
    border-bottom-right-radius: 2px;
    left: 50%;
    top: -1px;
    transform: translateX(-50%);
    background: var(--primary);
    content: ${props => (props.active ? `''` : 'none')};
  }
`;

export const PageTitle = styled.div`
  width: 100%;
  margin-top: 4px;
  overflow: hidden;
  white-space: nowrap;
  font-size: 0.7rem;
  text-align: center;
  text-overflow: ellipsis;
`;
