import React, { useEffect, useState } from 'react';

import IosIcon from './assets/ios.svg';
import AndroidIcon from './assets/android.svg';
import { PlatformIcons, PlatformIcon } from './style';

const icons = { ios: IosIcon, android: AndroidIcon };

const fetchPlatforms = (id, customFields) =>
  fetch(`https://api.trello.com/1/cards/${id}?fields=customFieldItems&customFieldItems=true`)
    .then(r => r.json())
    .then(cardCustomFields => {
      let platforms = [];

      if(cardCustomFields && cardCustomFields.customFieldItems) {
        cardCustomFields.customFieldItems.forEach(field => {
          const customField = customFields.find(c => c.id === field.idCustomField);
          if (customField) {
            if (customField.name === 'iOS') {
              platforms.push('ios');
            } else if (customField.name === 'Android') {
              platforms.push('android');
            }
          }
        });
      }

      return platforms;
    });

const CardPlatforms = ({ card: { id }, customFields }) => {
  const [platforms, setPlatforms] = useState([]);

  useEffect(() => {
    fetchPlatforms(id, customFields).then(p => setPlatforms(p));
  }, [id, customFields]);

  return (
    <PlatformIcons>
      {platforms.map(platform => {
        const Icon = icons[platform];
        return (
          <PlatformIcon key={platform}>
            <Icon />
          </PlatformIcon>
        )
      })}
    </PlatformIcons>
  )
};

export default CardPlatforms;
