import { decode } from 'html-entities';

// Load latest posts with the tag `platform_docs_site` from the
// Poq blog and inject into the empty "what's new" items.
const fetchPosts = count => {
  return fetch(
    `https://poqcommerce.com/wp-json/wp/v2/posts?tax_topics=40&per_page=${count}&_embed`
  )
    .then(res => res.json())
    .then(posts =>
      posts.map(post => ({
        link: post.link,
        thumbnail:
          post._embedded['wp:featuredmedia'][0].media_details.sizes.medium_large
            .source_url,
        title: post.title.rendered,
        excerpt: decode(
          post.excerpt.rendered
            .replace('<p>', '')
            .replace('</p>', '')
            .trim()
        )
      }))
    );
};

export default fetchPosts;
