import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';

import parseLinksToTree, { frontmatterKeys } from './parseLinksToTree';

const withToc = Component => props => {
  const data = useStaticQuery(graphql`
    {
      allMdx {
        edges {
          node {
            fields {
              slug
            }
            frontmatter {
              icon
              published
              sortOrder
              title
              shortTitle
            }
          }
        }
      }
    }
  `);

  const {
    allMdx: { edges: pages }
  } = data;

  const simplePages = pages
    .filter(({ node }) => node.frontmatter.published !== false)
    .map(({ node }) => {
      const page = {};
      frontmatterKeys.forEach(key => {
        page[key] = node.frontmatter[key];
      });
      page.path = node.fields.slug;

      return page;
    });

  const tree = parseLinksToTree(simplePages);

  return <Component toc={tree} {...props} />;
};

export default withToc;
