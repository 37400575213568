import React from 'react';

import Devices from './Devices';
import { HeroArea, HeroContainer, HeroColumn, Cta } from './style';
import ArrowRight from './arrow-right-regular.svg';

const Hero = () => (
  <HeroArea>
    <HeroContainer>
      <HeroColumn>
        <h2>Take your retail app to the next level with the poq platform</h2>
        <Cta to="/guides/quick-start">
          Get started <ArrowRight />
        </Cta>
      </HeroColumn>
      <Devices />
    </HeroContainer>
  </HeroArea>
);

export default Hero;
