import styled from 'styled-components';

export const AlertArea = styled.div`
  position: relative;
  margin: 32px 0;
  padding: 24px 24px 24px 50px;
  border: none;
  border-left: 4px solid var(--${props => props.type});
  border-radius: 4px;
  box-shadow: none;
  background: ${props => props.theme.isDark ? '#1b1a1f' : '#f5f7f9'};
  transition: background 0.2s ease-in-out;

  p {
    margin: 0 0 24px;
  }

  p:last-child {
    margin: 0;
  }
`;


export const AlertIcon = styled.div`
  color: var(--${props => props.type});

  svg {
    position: absolute;
    top: 23px;
    left: 15px;
    width: 24px;
    height: 24px;
  }
`;
