import React from 'react';

import {
  Phone,
  PhoneFrame,
  PhoneScreen,
  Pdp,
  Review,
  Modular,
  ClickCollect,
  ClickCollectSkeleton,
  OutOfStock,
  NotifyMeButton,
  Description
} from './style';
import TShirt from './tshirt-duotone.svg';
import Tv from './tv-retro-duotone.svg';
import BagsShopping from './bags-shopping-duotone.svg';
import BirthdayCake from './birthday-cake-duotone.svg';
import Star from './star-solid.svg';
import MapMarker from './map-marker-alt-duotone.svg';
import Bell from './bell-solid.svg';

const ProductScreen = () => (
  <Phone>
    <PhoneFrame />
    <PhoneScreen>
      <Pdp>
        <TShirt />
        <Tv />
        <BagsShopping />
        <BirthdayCake />
      </Pdp>
      <Review>
        <Star />
        <Star />
        <Star />
        <Star />
        <Star />
      </Review>
      <Modular>
        <ClickCollect>
          <ClickCollectSkeleton />
          <MapMarker />
        </ClickCollect>
        <OutOfStock>
          <NotifyMeButton>
            <Bell />
          </NotifyMeButton>
          <ClickCollectSkeleton />
        </OutOfStock>
        <Description>
          <ClickCollectSkeleton />
          <ClickCollectSkeleton />
          <ClickCollectSkeleton />
        </Description>
      </Modular>
    </PhoneScreen>
  </Phone>
);

export default ProductScreen;
