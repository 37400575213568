import React, { useEffect, useState } from 'react';

import Spinner from '../../components/Spinner';
import fetchTimeline from './fetchTimeline';
// See: https://www.gatsbyjs.com/plugins/gatsby-plugin-loadable-components-ssr/
import List from './ListLoadable';
import { Timeline, EmptyState } from './style';

const TrelloTimeline = ({ boardId }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [response, setResponse] = useState(null);
  const [error, setError] = useState(false);

  useEffect(() => {
    fetchTimeline(boardId)
      .then(res => {
        setResponse(res);
        setIsLoading(false);
      })
      .catch(err => {
        if (err.message === 'IP_LIMIT_EXCEEDED') {
          setError(
            'You have requested the data from our releases Trello board too frequently. Please try again later.'
          );
        } else {
          setError(err.message);
        }
        setIsLoading(false);
      });
  }, [boardId]);

  return (
    <Timeline isLoading={isLoading || error}>
      {isLoading ? (
        <EmptyState>
          <Spinner />
          <br />
          <p>Gathering latest release information</p>
        </EmptyState>
      ) : error ? (
        <EmptyState>
          <p>Error: {error}</p>
        </EmptyState>
      ) : (
        response.lists.map((list, i) => (
          <List
            list={list}
            customFields={response.customFields}
            index={i}
            key={i}
          />
        ))
      )}
    </Timeline>
  );
};

export default TrelloTimeline;
