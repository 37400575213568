import React from 'react';

import {
  Phone2,
  PhoneFrame,
  PhoneScreen,
  Loyalty,
  LoyaltyWrapper,
  LoyaltyCircle,
  GiftWrapper,
  WishlistItem,
  WishlistSkeleton,
  WishlistHeart
} from './style';
import Heart from './heart-solid.svg';
import Gift from './gift-duotone.svg';

const LoyaltyScreen = () => (
  <Phone2>
    <PhoneFrame />
    <PhoneScreen>
      <Loyalty>
        <LoyaltyWrapper>
          <LoyaltyCircle />
        </LoyaltyWrapper>
        <LoyaltyWrapper>
          <LoyaltyCircle />
        </LoyaltyWrapper>
        <GiftWrapper>
          <Gift />
        </GiftWrapper>
      </Loyalty>
      <div>
        <WishlistItem>
          <WishlistSkeleton />
          <WishlistHeart>
            <Heart />
          </WishlistHeart>
        </WishlistItem>
        <WishlistItem>
          <WishlistSkeleton />
          <WishlistHeart>
            <Heart />
          </WishlistHeart>
        </WishlistItem>
        <WishlistItem>
          <WishlistSkeleton />
          <WishlistHeart>
            <Heart />
          </WishlistHeart>
        </WishlistItem>
      </div>
    </PhoneScreen>
  </Phone2>
);

export default LoyaltyScreen;
