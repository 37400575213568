import React, { useState } from 'react';
import ReactMarkdown from 'react-markdown';

import CardPlatforms from './CardPlatforms';
import CoverImage from './CoverImage';
import {
  ListHeader,
  Event,
  EventPoint,
  EventContainer,
  FeatureCard,
  CardMeta,
  Badge,
  EventContent
} from './style';

const List = ({ customFields, index, list }) => {
  const [isOpen, setOpen] = useState(index === 0);

  return (
    <>
      <ListHeader>
        <EventPoint />
        <button onClick={() => setOpen(!isOpen)}>
          <h2>{list.name}</h2>
          <p>Release information for {list.name}</p>
          <svg
            width="18"
            height="18"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 448 512"
          >
            {isOpen ? (
              <path d="M240.971 130.524l194.343 194.343c9.373 9.373 9.373 24.569 0 33.941l-22.667 22.667c-9.357 9.357-24.522 9.375-33.901.04L224 227.495 69.255 381.516c-9.379 9.335-24.544 9.317-33.901-.04l-22.667-22.667c-9.373-9.373-9.373-24.569 0-33.941L207.03 130.525c9.372-9.373 24.568-9.373 33.941-.001z" />
            ) : (
              <path d="M207.029 381.476L12.686 187.132c-9.373-9.373-9.373-24.569 0-33.941l22.667-22.667c9.357-9.357 24.522-9.375 33.901-.04L224 284.505l154.745-154.021c9.379-9.335 24.544-9.317 33.901.04l22.667 22.667c9.373 9.373 9.373 24.569 0 33.941L240.971 381.476c-9.373 9.372-24.569 9.372-33.942 0z" />
            )}
          </svg>
        </button>
      </ListHeader>
      {isOpen &&
        list.cards.map((card, i) => (
          <Event key={i}>
            <EventContainer>
              <FeatureCard>
                <CardMeta>
                  {card.labels.map(label => (
                    <Badge color={label.color} key={label.id}>
                      {label.name}
                    </Badge>
                  ))}
                  <CardPlatforms card={card} customFields={customFields} />
                </CardMeta>
                <h3>{card.name}</h3>
                <EventContent>
                  {card.cover && card.cover.idAttachment && (
                    <CoverImage card={card} />
                  )}
                  <div>
                    <ReactMarkdown children={card.desc} />
                  </div>
                </EventContent>
              </FeatureCard>
            </EventContainer>
          </Event>
        ))}
    </>
  );
};

export default List;
