import React, { useEffect, useState } from 'react';

import userManager from './userManager';

export const AuthContext = React.createContext(null);

export const getUser = () =>
  userManager
    .getUser()
    .then(user => {
      if (user && !user.expired) {
        return user;
      } else {
        return userManager.signinSilent();
      }
    })
    .catch(err => {
      // Only spit out errors if the user is actually not logged in
      if (err.message !== 'login_required') {
        console.error(err);
      }
    });

const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);

  useEffect(() => {
    const req = async () => {
      const u = await getUser();
      if (u) {
        setUser(u);
      }
    };
    req();
  }, []);

  return <AuthContext.Provider value={user}>{children}</AuthContext.Provider>;
};

export default AuthProvider;
