import React from 'react';
import styled, { createGlobalStyle } from 'styled-components';
import { Hit as FallbackHit } from '@docsearch/react/dist/esm/Hit';
import { deepMap } from 'react-children-utilities';

const PlatformContainer = styled.div`
  display: flex;
  align-items: center;
`;

const Tag = styled.span`
  margin-right: 0.5rem;
  padding: 2px 6px;
  border-radius: 4px;
  background: rgba(125, 66, 184, 0.3);
  font-size: 0.66rem;
  text-transform: uppercase;
  line-height: 1.4;
  color: var(--primary);
`;

export const HitSelectedStyles = createGlobalStyle`
  .DocSearch-Hit[aria-selected=true] ${Tag} {
    background: #fff;
  }
`;

// Add platform tags to search results for developer pages
const Hit = ({ hit, children }) => {
  if (hit.url.indexOf('/developers/') > -1) {
    const platformMatch = hit.url.match('/developers/([A-Za-z-]+)/');
    if (platformMatch) {
      const platform = platformMatch[1];

      children = deepMap(children, child => {
        if (child && child.props.className === 'DocSearch-Hit-title') {
          return (
            <PlatformContainer>
              <Tag>{platform}</Tag>
              {child}
            </PlatformContainer>
          );
        }

        return child;
      });
    }
  }

  return <FallbackHit hit={hit}>{children}</FallbackHit>;
};

export default Hit;
