import { List, Map } from 'immutable';

export const frontmatterKeys = [
  'date',
  'icon',
  'path',
  'sortOrder',
  'title',
  'shortTitle'
];

const parseLinksToTree = pages => {
  let tree = Map();

  pages.forEach(page => {
    const path = page.path.split('/').slice(1);
    for (let i = 1; i <= path.length; i++) {
      if (!tree.getIn(path.slice(0, i))) {
        tree = tree.setIn(path.slice(0, i), Map());
      }
    }

    frontmatterKeys.forEach(key => {
      if (typeof page[key] !== 'undefined') {
        tree = tree.setIn([...path, key], page[key]);
      }
    });
  });

  const makeChildren = node => {
    let newNode = node;
    let children = List();

    // Create the list of children from the flat map of frontmatter and children
    node.forEach((value, key) => {
      if (frontmatterKeys.indexOf(key) === -1) {
        children = children.push(makeChildren(value));
        newNode = newNode.delete(key);
      }
    });

    if (children.size) {
      children = children.sortBy(c => c.get('title'));
      children = children.sortBy(c => c.get('sortOrder') || Infinity);
      newNode = newNode.set('children', children);
    }

    return newNode;
  };

  tree = makeChildren(tree);
  tree = tree
    .get('children')
    .sortBy(c => c.get('title'))
    .sortBy(c => c.get('sortOrder') || Infinity);

  return tree.toJS();
};

export default parseLinksToTree;
