import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { Tabs, Tab } from '../Tabs';
import CodeBlock from '../CodeBlock';
import { LanguageTabsArea } from './style';

const translations = {
  cs: '.NET',
  swift: 'Swift',
  java: 'Java',
  kotlin: 'Kotlin',
  js: 'JavaScript',
  php: 'PHP'
};

const LanguageTabs = ({ languages }) => {
  const [selectedIndex, setIndex] = useState(0);

  return (
    <LanguageTabsArea>
      <Tabs onSelect={setIndex} selectedIndex={selectedIndex} noPanelPadding>
        {Object.keys(languages).map(lang => (
          <Tab label={translations[lang] || lang} key={lang}>
            <CodeBlock code={languages[lang]} language={lang} />
          </Tab>
        ))}
      </Tabs>
    </LanguageTabsArea>
  );
};

LanguageTabs.propTypes = { languages: PropTypes.object.isRequired };

export default LanguageTabs;
