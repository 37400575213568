import styled from 'styled-components';

export const Phone2 = styled.div`
  position: absolute;
  top: 40px;
  right: -50px;
  width: 214px;
  height: 426px;
  transform: rotate(2deg);

  @media (min-width: 768px) {
    top: -340px;
    right: 0;
  }

  @media (min-width: 992px) {
    top: 0;
    right: 100px;
  }

  @media (min-width: 1280px) {
    top: 100px;
    right: 170px;
  }
`;

export const PhoneFrame = styled.div`
  position: absolute;
  top: -16px;
  left: -16px;
  right: -16px;
  bottom: -16px;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 3px 18px 0px inset,
    rgba(0, 0, 0, 0.1) 0px 12px 48px 0px,
    rgba(255, 255, 255, 0.04) 0px 2px 3px 0px inset;
  border-radius: 38px;
  background: #18154d;
`;

export const PhoneScreen = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: hidden;
  border-radius: 26px;
  background: linear-gradient(40deg, #00d4ff, #d0f5f5);
`;

export const Loyalty = styled.div`
  position: relative;
  margin: 24px auto;
  width: 200px;
  height: 160px;
`;

export const LoyaltyCircle = styled.div`
  width: 160px;
  height: 160px;
  border: 10px solid transparent;
  border-radius: 50%;
  position: absolute;
  top: 0;
  transform: rotate(-135deg);
`;

export const LoyaltyWrapper = styled.div`
  width: 100px;
  height: 200px;
  position: absolute;
  top: 0;
  overflow: hidden;

  &:first-child {
    right: 0px;

    ${LoyaltyCircle} {
      border-top: 10px solid #1b1857;
      border-right: 10px solid #1b1857;
      right: 21px;
      animation: circleRight 5s linear infinite;
    }
  }
  &:nth-child(2) {
    left: 0px;

    ${LoyaltyCircle} {
      border-bottom: 10px solid #1b1857;
      border-left: 10px solid #1b1857;
      left: 21px;
      animation: circleLeft 5s linear infinite;
      will-change: opacity, transform;
    }
  }

  @keyframes circleRight {
    0% {
      opacity: 1;
      transform: rotate(-135deg);
    }
    20%,
    90% {
      opacity: 1;
      transform: rotate(45deg);
    }
    100% {
      opacity: 0;
      transform: rotate(45deg);
    }
  }
  @keyframes circleLeft {
    0%,
    20% {
      opacity: 1;
      transform: rotate(-135deg);
    }
    40%,
    90% {
      opacity: 1;
      transform: rotate(45deg);
    }
    100% {
      opacity: 0;
      transform: rotate(45deg);
    }
  }
`;

export const GiftWrapper = styled.div`
  animation: gift 5s infinite;
  will-change: opacity, transform;

  svg {
    position: absolute;
    top: 40px;
    left: 50%;
    width: 72px;
    height: 72px;
    transform: translateX(-50%);
  }

  @keyframes gift {
    0%,
    42% {
      opacity: 0;
      transform: scale(0.8);
    }

    48%,
    97% {
      opacity: 1;
      transform: scale(1);
    }

    100% {
      opacity: 0;
    }
  }
`;

export const WishlistItem = styled.div`
  display: flex;
  align-items: center;
  padding: 16px;

  &:first-child {
    margin-top: 16px;
  }

  &:nth-child(2) {
    svg {
      animation-delay: 0.2s;
    }
  }

  &:nth-child(3) {
    svg {
      animation-delay: 0.4s;
    }
  }
`;

export const WishlistHeart = styled.div`
  margin-left: auto;

  svg {
    width: 32px;
    height: 32px;
    animation: pulse 1.5s infinite;
    will-change: transform;
  }

  @keyframes pulse {
    10% {
      transform: scale(1.2);
    }
  }
`;

export const WishlistSkeleton = styled.div`
  flex: 1;
  height: 10px;
  margin-right: 16px;
  border-radius: 4px;
  background: rgba(255, 255, 255, 0.4);
  transform: translateY(-8px);

  &:after {
    display: block;
    height: 10px;
    border-radius: 4px;
    background: rgba(255, 255, 255, 0.4);
    transform: translateY(18px);
    content: '';
  }
`;
