import styled from 'styled-components';

const Homepage = styled.div`
  width: 100%;
  background: ${props => props.theme.bg};
  color: ${props => props.theme.fg};
  transition: background 0.2s ease-in-out, color 0.2s ease-in-out;
`;

export default Homepage;
