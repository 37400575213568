import styled from 'styled-components';

export const Timeline = styled.div`
  position: relative;
  margin-top: 24px;

  @media (min-width: 768px) {
    padding: 5px 50px;
  }

  &:before {
    position: absolute;
    top: 12px;
    left: 9px;
    bottom: 20px;
    width: 1px;
    background: rgba(116, 87, 146, 0.15);
    content: ${props => (props.isLoading ? 'none' : `''`)};

    @media (min-width: 768px) {
      left: 21.5px;
    }
  }
`;

export const EmptyState = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 72px 0;
  text-align: center;
`;

export const ListHeader = styled.div`
  clear: both;
  text-align: left;
  position: relative;

  button {
    position: relative;
    float: right;
    width: 90%;
    margin: 0 0 2em;
    padding-left: 5px;
    border: none;
    background: none;
    text-align: left;
    cursor: pointer;
    color: inherit;

    @media (min-width: 768px) {
      width: 100%;
    }

    svg {
      position: absolute;
      top: 50%;
      right: 16px;
      transform: translateY(-50%);
      color: ${props =>
        props.theme.isDark ? 'rgba(255, 255, 255, 0.2)' : 'rgba(0, 0, 0, 0.2)'};
    }
  }

  h2 {
    margin: 0 0 10px;
    font-size: 14px;
    font-weight: 600;
    line-height: 26px;
  }

  p {
    margin-bottom: 0;
    max-width: 350px;
    font-size: 14px;
    line-height: 28px;
    color: ${props =>
      props.theme.isDark ? 'rgba(255, 255, 255, 0.55)' : 'rgba(0, 0, 0, 0.55)'};
    transition: color 0.2s ease-in-out;
  }
`;

export const Event = styled.div`
  clear: both;
  text-align: left;
  position: relative;
`;

export const EventPoint = styled.div`
  &:before {
    width: 26px;
    height: 26px;
    opacity: 0.18;
    position: absolute;
    left: -3px;
    top: -1px;
    border-radius: 30px;
    background: linear-gradient(
      44.28deg,
      rgb(162, 120, 204) 0%,
      rgb(138, 84, 191) 101.29%
    );
    content: '';
  }

  &:after {
    position: absolute;
    width: 12px;
    height: 12px;
    top: 6px;
    left: 4px;
    border-radius: 12px;
    background: linear-gradient(
      44.28deg,
      rgb(125, 66, 184) 0%,
      rgb(175, 139, 212) 101.29%
    );
    content: '';
  }

  @media (min-width: 768px) {
    &:before {
      left: -41px;
    }

    &:after {
      left: -34px;
    }
  }
`;

export const EventContainer = styled.div`
  position: relative;
  float: right;
  width: 90%;
  margin: 0 0 2em;
  padding-left: 5px;

  @media (min-width: 768px) {
    width: 100%;
  }
`;

export const FeatureCard = styled.div`
  width: 100%;
  height: auto;
  padding: 0 30px 30px;
  border-radius: 6px;
  box-shadow: rgba(0, 0, 0, 0.12) 0 4px 6px 0;
  background: ${props => (props.theme.isDark ? '#1b1a1f' : '#f8fafc')};

  h3 {
    margin: 6px 0 10px;
    font-size: 20px;
    font-weight: 600;
  }
`;

export const CardMeta = styled.div`
  display: flex;
  padding-top: 16px;
`;

export const MetaRight = styled.div`
  margin-left: auto;
`;

const badges = {
  purple: {
    background: 'rgba(210, 173, 247, 0.5)',
    color: '#a25ee6'
  },
  orange: {
    background: 'rgba(255, 193, 86, 0.2)',
    color: '#f59e08'
  },
  sky: {
    background: 'rgba(158, 239, 255, 0.5)',
    color: '#4ab2c7'
  },
  green: {
    background: 'rgba(103, 220, 201, 0.2)',
    color: '#0da88f'
  },
  danger: {
    background: 'rgba(255, 200, 208, 0.5)',
    color: '#de3d54'
  },
  black: {
    background: 'rgba(164, 188, 230, 0.5)',
    color: '#55698c'
  }
};

export const Badge = styled.div`
  display: inline-flex;
  align-items: center;
  margin-right: 8px;
  padding: 2px 10px 1px;
  border-radius: 24px;
  background: ${props =>
    badges[props.color] ? badges[props.color].background : '#efefef'};
  font-size: 14px;
  font-weight: 600;
  white-space: nowrap;
  line-height: 1.5;
  color: ${props => (badges[props.color] ? badges[props.color].color : '#333')};

  &:last-child {
    margin-right: 0;
  }
`;

export const PlatformIcons = styled.div`
  display: flex;
  align-items: center;
  position: absolute;
  top: 16px;
  right: 28px;
`;

export const PlatformIcon = styled.div`
  width: 24px;
  margin-right: 8px;
  color: #8e8e93;

  &:last-child {
    margin-right: 0;
  }
`;

export const EventContent = styled.div`
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;

  img {
    width: 100%;
  }

  @media (min-width: 768px) {
    flex-wrap: unset;

    img {
      width: 33%;
      min-width: 33%;
      margin-right: 16px;
    }
  }
`;

export const CoverPlaceholder = styled.div`
  @media (min-width: 768px) {
    min-width: 33%;
    margin-right: 16px;
  }
`;
