/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */
import 'remove-focus-outline';

export const onRouteUpdate = () => {
  const { hash } = window.location;

  if (hash) {
    const id = hash.slice(1);

    const heading = document.getElementById(id);
    if (heading) {
      const placeholderEl = document.createElement('div');
      placeholderEl.id = id;
      placeholderEl.classList.add('anchor-heading');

      // Before updating location.hash we must set at least one state in the History API
      window.history.replaceState({}, '');

      // Move the anchor to the placeholder and update the hash
      heading.removeAttribute('id');
      heading.insertAdjacentElement('beforebegin', placeholderEl);
      window.location.hash = '';
      window.location.hash = hash;

      window.setTimeout(() => {
        placeholderEl.parentNode.removeChild(placeholderEl);
        heading.setAttribute('id', id);
      }, 1);
    }
  }
};

window.gatsbyOnRouteUpdate = onRouteUpdate;
