import React, { useState } from 'react';
import { useWindowScroll } from 'beautiful-react-hooks';
import { Link } from 'gatsby';

import Search from '../Search';
import AuthUser from './AuthUser';
import { Navbar, NavbarLeft, Logo, DocsBadge, NavbarRight } from './style';
import PoqLogo from './assets/logo.svg';

const Header = ({ isHomepage }) => {
  // Hide the list until the user scrolls down
  const [scrollY, setScrollY] = useState(
    typeof window !== 'undefined' ? window.scrollY : 0
  );
  useWindowScroll(() =>
    setScrollY(typeof window !== 'undefined' ? window.scrollY : 0)
  );
  const isHomepageOrScrolled = scrollY > 50 ? false : isHomepage;

  return (
    <Navbar role="navigation" isHomepage={isHomepageOrScrolled}>
      <div className="container">
        <NavbarLeft>
          <Logo isHomepage={isHomepageOrScrolled}>
            <Link to="/" aria-label="Go to homepage">
              <PoqLogo />
              <DocsBadge isHomepage={isHomepageOrScrolled}>Docs</DocsBadge>
            </Link>
          </Logo>
          <Search isHomepage={isHomepageOrScrolled} />
        </NavbarLeft>
        <NavbarRight>
          <AuthUser isHomepage={isHomepageOrScrolled} />
        </NavbarRight>
      </div>
    </Navbar>
  );
};

export default Header;
