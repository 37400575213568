import styled, { css } from 'styled-components';

export const tabPanelStyles = css`
  padding: ${props => (props.noPanelPadding ? 0 : '16px')};
  border: ${props =>
    props.noPanelPadding
      ? 'none'
      : `1px solid ${props.theme.isDark ? '#2c2b2f' : '#e6ecf1'}`};
  border-radius: 0 4px 4px 4px;
  transition: border-color 0.2s ease-in-out;

  > pre:first-child,
  > p:first-child,
  > ul:first-child,
  > ol:first-child {
    margin-top: 0;
  }

  > pre:last-child,
  > p:last-child,
  > ul:last-child,
  > ol:last-child {
    margin-bottom: 0;
  }
`;

// HACK: Hiding the heading anchor because it won't open the tab on page load
export const TabsArea = styled.div`
  .react-tabs__tab-list {
    margin: 0;
    border: none;
  }

  .react-tabs__tab {
    margin-right: 4px;
    border: 1px solid ${props => (props.theme.isDark ? '#2c2b2f' : '#e6ecf1')};
    border-radius: 4px 4px 0 0 !important;
    background: ${props => (props.theme.isDark ? '#080808' : '#f5f7f9')};
    color: ${props => (props.theme.isDark ? '#fff' : '#9daab6')};
    transition: all 0.2s ease-in-out;

    &:last-child {
      margin-right: 0;
    }
  }

  .react-tabs__tab--selected {
    border-bottom-color: ${props => props.theme.bg};
    background: ${props => (props.theme.isDark ? props.theme.bg : '#fff')};
    color: ${props => (props.theme.isDark ? '#fff' : '#242a31')};
  }

  .react-tabs__tab-panel {
    ${tabPanelStyles};
  }

  .anchor {
    border-radius: 50%;
    background: #fff;
  }
`;
