import { UserManager, WebStorageStateStore } from 'oidc-client';

const baseUrl =
  process.env.NODE_ENV === 'development'
    ? 'http://localhost:8000'
    : 'https://docs.poqcommerce.com';

const userManager = UserManager
  ? new UserManager({
      authority: 'https://id.appmanager.poq.io',
      client_id: 'poq.docs',
      redirect_uri: `${baseUrl}/auth/callback`,
      post_logout_redirect_uri: baseUrl,
      silent_redirect_uri: `${baseUrl}/auth/renew`,
      response_type: 'token id_token',
      scope: 'openid profile role',
      // automaticSilentRenew: true,
      filterProtocolClaims: true,
      loadUserInfo: true,
      userStore: WebStorageStateStore
        ? new WebStorageStateStore({ store: window.localStorage })
        : null
    })
  : {};

export default userManager;
