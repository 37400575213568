import React, { useEffect, useState } from 'react';

import { CoverPlaceholder } from './style';

const fetchCoverImage = (cardId, attachmentId) =>
  fetch(`https://api.trello.com/1/cards/${cardId}/attachments/${attachmentId}`)
    .then(r => r.json())
    .then(attachment => {
      if (attachment.previews && attachment.previews.length > 0) {
        const preview = attachment.previews.find(p => p.width === 600);

        if (preview) {
          return preview.url;
        } else {
          return attachment.previews[0].url;
        }
      }
    });

const CoverImage = ({
  card: {
    id,
    cover: { idAttachment }
  }
}) => {
  const [url, setUrl] = useState(null);

  useEffect(() => {
    fetchCoverImage(id, idAttachment).then(url => setUrl(url));
  }, [id, idAttachment]);

  if (!url) {
    if (idAttachment) {
      return <CoverPlaceholder />;
    }

    return null;
  }

  return <img src={url} alt="" />;
};

export default CoverImage;
