/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from 'react';
import { Helmet } from 'react-helmet';
import { useStaticQuery, graphql } from 'gatsby';
import PropTypes from 'prop-types';

import 'glightbox/dist/css/glightbox.css';
import 'docsearch.js/dist/cdn/docsearch.css';

import Header from '../Header';
import BottomNavigation from '../BottomNavigation';
import './normalize.css';
import './layout.css';
import { Main } from './style';
import GlobalStyles from './GlobalStyles';

const Layout = ({ children, isHomepage, pathname }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);

  return (
    <>
      <Helmet
        defaultTitle={data.site.siteMetadata.title}
        titleTemplate={`%s • ${data.site.siteMetadata.title}`}
      >
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
        <link
          rel="preconnect"
          href={`https://${process.env.GATSBY_DOCSEARCH_APP_ID}-dsn.algolia.net`}
          crossorigin
        />
        <link
          href="https://fonts.googleapis.com/css2?family=Red+Hat+Display:wght@500;700&family=Roboto:wght@400;700&display=swap"
          rel="stylesheet"
        />
      </Helmet>
      <GlobalStyles />
      <Header isHomepage={isHomepage} />
      <Main>{children}</Main>
      <BottomNavigation pathname={pathname} />
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  isHomepage: PropTypes.bool,
  pathname: PropTypes.string.isRequired
};

export default Layout;
