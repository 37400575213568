import styled from 'styled-components';

export const WhatsNewSection = styled.section`
  margin-bottom: 100px;
`;

export const WhatsNewTitle = styled.h2`
  position: relative;
  top: -16px;
  font-size: 1.75rem;
`;

export const Items = styled.div`
  display: flex;
  flex-wrap: wrap;

  @media (min-width: 992px) {
    margin: 0 -15px;
  }
`;

export const Post = styled.a`
  width: 100%;
  padding: 0 15px;
  color: inherit;

  @media (min-width: 768px) {
    width: 33%;
  }

  p {
    min-height: 150px;
  }
`;

export const Thumbnail = styled.img`
  display: block;
  width: 100%;
  max-height: 228px;
  object-fit: cover;
  border-radius: 4px;
  padding-bottom: ${props => props.loaded ? 0 : '62%'};
  background: #000;
  opacity: ${props => props.loaded ? 1 : 0.04};
  transition: opacity 0.3s;
`;

/* eslint-disable-next-line */
export default () => {};
