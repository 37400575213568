import React, { useState } from 'react';

import { CollapseButton, CollapseCaret, CollapseArea } from './style';

const Collapse = ({
  children,
  defaultOpen = false,
  noPanelPadding,
  summary
}) => {
  const [isOpen, setOpen] = useState(defaultOpen);

  return (
    <>
      <CollapseButton
        onClick={() => setOpen(!isOpen)}
        isOpen={isOpen}
        aria-expanded={isOpen}
      >
        <CollapseCaret>{isOpen ? '▼ ' : '► '}</CollapseCaret>
        {summary}
      </CollapseButton>
      {isOpen && (
        <CollapseArea noPanelPadding={noPanelPadding}>{children}</CollapseArea>
      )}
    </>
  );
};

export default Collapse;
