import React from 'react';
import styled from 'styled-components';
import { useStyledDarkMode } from 'gatsby-styled-components-dark-mode';

import { StickyNavItem } from '../Sidebar/style';
import Button from '../Button';

const ToggleBtn = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 50px;
  padding: 8px 16px;
  border-radius: 0;
  color: inherit;

  svg {
    color: ${props => props.theme.isDark ? '#303e8a' : '#f7c01f'};
    /* transition: color 0.2s ease-in-out; */
  }
`;

const DarkModeToggle = ({ theme }) => {
  const { isDark, toggleDark } = useStyledDarkMode();

  const labelTitle = `Activate ${isDark ? 'light' : 'dark'} mode`;

  return (
    <StickyNavItem>
      <ToggleBtn onClick={() => toggleDark()} title={labelTitle}>
        {isDark ? (
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
            <path d="M32 256C32 114.51 144.29 0 282.64 0a246.91 246.91 0 0145.8 4.28c10.77 2 13.16 16.72 3.67 22.23-61.52 35.76-99.37 102.17-99.37 174.39 0 125.38 111.67 220.61 233.28 197 10.69-2.08 18.24 10.64 11.32 19.35C430.56 476.08 360 512 282.64 512 144.11 512 32 397.31 32 256z"></path>
          </svg>
        ) : (
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
            <path
              d="M502.42 240.5l-94.7-47.3 33.5-100.4c4.5-13.6-8.4-26.5-21.9-21.9l-100.4 33.5-47.41-94.8a17.31 17.31 0 00-31 0l-47.3 94.7L92.7 70.8c-13.6-4.5-26.5 8.4-21.9 21.9l33.5 100.4-94.7 47.4a17.31 17.31 0 000 31l94.7 47.3-33.5 100.5c-4.5 13.6 8.4 26.5 21.9 21.9l100.41-33.5 47.3 94.7a17.31 17.31 0 0031 0l47.31-94.7 100.4 33.5c13.6 4.5 26.5-8.4 21.9-21.9l-33.5-100.4 94.7-47.3a17.33 17.33 0 00.2-31.1zm-155.9 106c-49.91 49.9-131.11 49.9-181 0a128.13 128.13 0 010-181c49.9-49.9 131.1-49.9 181 0a128.13 128.13 0 010 181z"
              opacity="0.4"
            ></path>
            <path d="M352 256a96 96 0 11-96-96 96.15 96.15 0 0196 96z"></path>
          </svg>
        )}
        Theme
      </ToggleBtn>
    </StickyNavItem>
  );
};

export default DarkModeToggle;
