import React from 'react';

import { AlertArea, AlertIcon } from './style';
import InfoIcon from './assets/info-circle-duotone.svg';
import SuccessIcon from './assets/check-circle-duotone.svg';
import WarningIcon from './assets/exclamation-triangle-duotone.svg';
import DangerIcon from './assets/times-octagon-duotone.svg';

const icons = {
  info: InfoIcon,
  success: SuccessIcon,
  warning: WarningIcon,
  danger: DangerIcon
};

const Alert = ({ children, type }) => {
  const Icon = icons[type];

  return (
    <AlertArea type={type}>
      {Icon && <AlertIcon type={type}><Icon /></AlertIcon>}
      {children}
    </AlertArea>
  );
};

export default Alert;
