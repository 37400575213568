import React from 'react';

import { ErrorArea } from './style';

const ErrorPage = ({ errorMessage, title }) => (
  <ErrorArea>
    <h1>{title}</h1>
    <p>{errorMessage}</p>
  </ErrorArea>
);

export default ErrorPage;
