import React from 'react';

import { HttpArea, HttpInfoArea, Method } from './style';

const HttpRequest = ({ children, endpoint, method }) => (
  <HttpArea>
    <HttpInfoArea>
      <Method method={method}>{method}</Method>
      <code>{endpoint}</code>
    </HttpInfoArea>
    {children}
  </HttpArea>
);

export default HttpRequest;
