import styled from 'styled-components';

const Container = styled.div`
  margin: 0 auto;

  @media (min-width: 992px) {
    width: 970px;
  }

  @media (min-width: 1200px) {
    width: 1170px;
  }
`;

export default Container;
